<div id="header-container" class="header component">
  <div class="header__wrapper">
    <div class="wrapper__product-logo">
      <img class="product-logo__image" src="./assets/img/logo-cockpit.svg" />
    </div>

    <div class="wrapper__company-logo">
      <img class="company-logo__image" src="./assets/img/forever-header-img.svg" />
    </div>

    <div class="wrapper__toggle">
      <i
        (click)="toggleMobileMenu(isVisible)"
        class="toggle_icon"
        [ngClass]="{
          'i--md--hamburger--spaced': !isVisible,
          'i--md--close--spaced': isVisible
        }"
      ></i>
    </div>
    <div class="wrapper__controls">
      <div class="controls__panel">
        <app-user-control-panel></app-user-control-panel>
      </div>
    </div>
  </div>
</div>
