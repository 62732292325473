import * as fromMessage from '../actions/core-message.actions';
import { ICoreMessage } from '../../models/message.interface';

export interface MessageState {
  error: ICoreMessage[];
  success: ICoreMessage[];
  warning: ICoreMessage[];
}

export const initialState: MessageState = {
  error: [],
  success: [],
  warning: [],
};

export function reducer(state = initialState, action: fromMessage.MessageAction): MessageState {
  switch (action.type) {
    case fromMessage.CLEAR_MESSAGES:
      return handleClearMessages(state);
    case fromMessage.ADD_SUCCESS_MESSAGE:
      return handleAddSuccessMessage(state, action.payload);
    case fromMessage.REMOVE_SUCCESS_MESSAGE:
      return handleRemoveSuccessMessage(state, action.payload);
    case fromMessage.ADD_WARNING_MESSAGE:
      return handleAddWarningMessage(state, action.payload);
    case fromMessage.REMOVE_WARNING_MESSAGE:
      return handleRemoveWarningMessage(state, action.payload);
    case fromMessage.ADD_ERROR_MESSAGE:
      return handleAddErrorMessage(state, action.payload);
    case fromMessage.REMOVE_ERROR_MESSAGE:
      return handleRemoveErrorMessage(state, action.payload);
  }
  return state;
}

function handleClearMessages(state): MessageState {
  return {...initialState};
}

function handleAddSuccessMessage(state, payload): MessageState {
  const success = [...state.success, payload];
  return {...state, success};
}

function handleRemoveSuccessMessage(state, payload): MessageState {
  state.success.splice(state.success.indexOf(payload));
  return {...state, success: state.success};
}

function handleAddWarningMessage(state, payload): MessageState {
  const warning = [...state.success, payload];
  return {...state, warning};
}

function handleRemoveWarningMessage(state, payload): MessageState {
  state.warning.splice(state.warning.indexOf(payload));
  return {...state, warning: state.warning};
}

function handleAddErrorMessage(state, payload): MessageState {
  const error = [...state.error, payload];
  return {...state, error};
}

function handleRemoveErrorMessage(state, payload): MessageState {
  state.error.splice(state.error.indexOf(payload));
  return {...state, error: state.error};
}

export const getErrorMessages = (state: MessageState) => state.error;
export const getSuccessMessages = (state: MessageState) => state.success;
export const getWarningMessages = (state: MessageState) => state.warning;
