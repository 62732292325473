import { Component, OnInit, Input } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { IUser } from '../../../models/user.interface';
import { TranslateService } from '@ngx-translate/core';
import { CoreStateFacade } from '../../../core-state.facade';
import * as _ from 'lodash';
import { environment } from '../../../../../../environments/environment';
import { map, takeWhile } from 'rxjs/operators';
import { UserControlPanelUtils } from '../../utils/user-control-panel.utils';

@Component({
    selector: 'app-user-control-panel',
    templateUrl: './user-control-panel.component.html',
    styleUrls: ['./user-control-panel.component.scss'],
})
export class UserControlPanelComponent implements OnInit {
    user$: Observable<IUser>;
    profileImgUrl$: Observable<string>;
    gender = 'm';
    isAuthenticated$: Observable<boolean>;
    selectedLanguage: string;
    selectedNotificationLanguage: string;
    selectedDropdown: string;

    languages = [
        { id: 'de', transKey: 'GERMAN' },
        { id: 'fr', transKey: 'FRENCH' },
        { id: 'it', transKey: 'ITALIAN' },
    ];

    quicklinks = [
        { label: 'YOURS', url: 'https://forever-yours.eu/' },
        { label: 'F.I.T.', url: 'http://www.tuedirwasgutes.com/' },
        { label: 'Shop', url: 'https://be-forever.de/' },
        { label: 'FBO Shop', url: 'https://www.flpg-versand.de' },
        { label: 'Forever', url: 'https://flp.de' },
    ];

    constructor(
        public facade: CoreStateFacade,
        public translate: TranslateService,
        private userControlPanelUtils: UserControlPanelUtils,
    ) {}

    ngOnInit() {
        this.user$ = this.facade.user$;
        this.profileImgUrl$ = this.facade.user$.pipe(
            map((user) => {
                if (user.profileImgUrl !== '' && user.profileImgUrl !== undefined && user.profileImgUrl !== null) {
                    return `${environment.mediaHost}${user.profileImgUrl}?${Date.now()}`;
                }
            }),
        );
        this.isAuthenticated$ = this.facade.isAuthenticated$;
        this.facade.languageSelection$.subscribe((language) => this.updateLanguage(language));
        this.user$.subscribe((user) => {
            this.gender = user.gender;
            this.personalizeQuicklinks(user);
        });
        this.facade.isAuthenticated$.subscribe((isAuthenticated) => {
            if (isAuthenticated) this.facade.loadUser();
        });
        fromEvent(window, 'resize').subscribe(() => {
            this.resetUI();
        });
    }

    setLanguage(language: string) {
        this.facade.storeLanguage(language);
    }

    isSelectedLanguage(language: string) {
        return language === this.selectedLanguage;
    }

    updateLanguage(language) {
        this.selectedLanguage = language;
        this.selectedNotificationLanguage = this.userControlPanelUtils.getUNNotificationTranslationFromUiLanguage(language);
        this.translate.use(language);
        if (document.getElementById('legacy')) {
            const iframe = <HTMLIFrameElement>document.getElementById('legacy');
            iframe.contentWindow.location.reload(true);
        }
    }

    personalizeQuicklinks(user) {
        const yoursKey = _.findKey(this.quicklinks, { label: 'YOURS' });
        const shopKey = _.findKey(this.quicklinks, { label: 'Shop' });
        if (user.yoursUrl) this.quicklinks[yoursKey].url = user.yoursUrl;
        if (user.shopUrl) this.quicklinks[shopKey].url = user.shopUrl;
    }

    transKey(subKey: string) {
        return 'USER_CONTROL_PANEL.' + subKey;
    }

    toggleDropdown(id: string) {
        this.facade.setUiMobileMenuHidden();
        const dropdowns: any = document.querySelectorAll('.dropdown-menu');
        if (id === this.selectedDropdown) {
            dropdowns.forEach((dropdown) => {
                dropdown.classList.remove('show');
                this.selectedDropdown = undefined;
            });
        } else {
            dropdowns.forEach((dropdown) => {
                if (dropdown.id === id) {
                    this.selectedDropdown = id;
                    dropdown.classList.add('show');
                } else {
                    dropdown.classList.remove('show');
                }
            });
        }
    }

    resetUI() {
        const dropdowns: any = document.querySelectorAll('.dropdown-menu');
        dropdowns.forEach((dropdown) => {
            dropdown.classList.remove('show');
        });
        this.selectedDropdown = undefined;
        this.facade.setUiMobileMenuHidden();
    }

    openExternalUrl(url: string) {
        window.open(url, '_blank');
    }
}
