import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import * as uiActions from '../actions/core-ui.actions';

@Injectable()
export class UiEffects {
  constructor(private actions$: Actions) {}

  @Effect()
  storeLanguageSelection$ = this.actions$.pipe(ofType(uiActions.STORE_UI_LANGUAGE_SELECTION)).pipe(
    map((action: uiActions.StoreUiLanguageSelection) => {
      let newLanguageSlug = '';
      if (action.payload === 'de') newLanguageSlug = 'de-de';
      if (action.payload === 'fr' || action.payload === 'it') newLanguageSlug = action.payload + '-ch';
      window.localStorage.setItem('cockpit.language', newLanguageSlug);
      return new uiActions.SetUiLanguageSelection(action.payload);
    }),
  );
}
