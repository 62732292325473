import * as fromUI from '../actions/core-ui.actions';

export interface UIState {
  authenticated: boolean;
  loading: boolean;
  language: string;
  mobileMenu: boolean;
  legacyMode: boolean;
}

export const initialState: UIState = {
  authenticated: false,
  loading: false,
  language: 'de',
  mobileMenu: false,
  legacyMode: false
};

export function reducer(state = initialState, action: fromUI.UIAction): UIState {
  switch (action.type) {
    case fromUI.CLEAR_UI_STATE:
     return handleClearUIState(state);
    case fromUI.SET_UI_LOADING_TRUE:
      return handleSetUILoadingTrue(state);
    case fromUI.SET_UI_LOADING_FALSE:
      return handleSetUILoadingFalse(state);
    case fromUI.SET_UI_STATE_AUTHENTICATED:
      return handleSetUIStateAuthenticated(state);
    case fromUI.SET_UI_STATE_DEAUTHENTICATED:
      return handleSetUIStateDeauthenticated(state);
    case fromUI.SET_UI_LANGUAGE_SELECTION:
      return handleSetUiLanguageSelection(state, action.payload);
    case fromUI.SET_MOBILE_MENU_HIDDEN:
      return handleSetUiMobileMenuHidden(state);
    case fromUI.SET_MOBILE_MENU_VISIBLE:
      return handleSetUiMobileMenuVisible(state);
    case fromUI.SET_LEGACY_MODE_FALSE:
      return handleSetUiLegacyModeFalse(state);
    case fromUI.SET_LEGACY_MODE_TRUE:
      return handleSetUiLegacyModeTrue(state);
  }
  return state;
}

function handleClearUIState(state): UIState {
  return {...initialState};
}

function handleSetUILoadingTrue(state): UIState {
  return {...state, loading: true};
}

function handleSetUILoadingFalse(state): UIState {
  return {...state, loading: false};
}

function handleSetUIStateAuthenticated(state): UIState {
  return {...state, authenticated: true};
}

function handleSetUIStateDeauthenticated(state): UIState {
  return {...state, authenticated: false};
}

function handleSetUiLanguageSelection(state, newLanguage): UIState {
  return {...state, language: newLanguage};
}

function handleSetUiMobileMenuVisible(state): UIState {
  return {...state, mobileMenu: true};
}

function handleSetUiMobileMenuHidden(state): UIState {
  return {...state, mobileMenu: false};
}

function handleSetUiLegacyModeTrue(state): UIState {
  return {...state, legacyMode: true};
}

function handleSetUiLegacyModeFalse(state): UIState {
  return {...state, legacyMode: false};
}

export const getUIAuthenticated = (state: UIState) => state.authenticated;
export const getUILoading = (state: UIState) => state.loading;
export const getUILanguage = (state: UIState) => state.language;
export const getUIMobileMenuState = (state: UIState) => state.mobileMenu;
export const getUILegacyMode = (state: UIState) => state.legacyMode;
