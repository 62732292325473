import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromServices from '../../services';
import * as fromUISidebarActions from '../actions/core-ui-sidebar.actions';

@Injectable()
export class UISidebarEffects {
  constructor(private actions$: Actions, private userService: fromServices.UserService) {}

  @Effect()
  loadContactCount$ = this.actions$.pipe(ofType(fromUISidebarActions.LOAD_USER_CONTACTCOUNT)).pipe(
    switchMap(() => {
      return this.userService.getUserContactCount().pipe(
        map(response => new fromUISidebarActions.LoadUserContactCountSuccess(response.contactCount)),
        catchError(error => of(new fromUISidebarActions.LoadUserContactCountFail(error))),
      );
    }),
  );
}
