import { takeWhile } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreStateFacade } from '../../core-state.facade';
import { Router } from '@angular/router';

import { environment } from '../../../../../environments/environment';
import INavigation, { INavPoint, INavSubPoint } from '../../models/navigation.interface';
import { environmentEnums } from '../../../../../environments/environment.enums';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  selectedNavPoint: any = null;
  alive = true;
  contactCount = 0;
  navStructure: INavigation = [
    {
      english: 'Dashboard',
      transKey: 'DASHBOARD.DASHBOARD_MAIN_TITLE',
      link: '/dashboard',
    },
    {
      english: 'Notifications',
      transKey: 'SIDEBAR.NOTIFICATIONS',
      link: '/dashboard/notifications',
    },
    {
      english: 'My Profile',
      transKey: 'SIDEBAR.MY_PROFILES',
      link: '',
      subLinks: [
        {
          english: 'Cockpit Profile',
          transKey: 'SIDEBAR.COCKPIT_PROFILE',
          link: '/user/profile',
        },
        /* {
          english: 'CC Overview',
          hidden: !(environment.name === environmentEnums.LOCAL || environment.name === environmentEnums.DEVELOPMENT),
          transKey: 'SIDEBAR.CC_OVERVIEW',
          link: '/user/profile',
        },
        {
          english: 'Web Business Card',
          hidden: !(environment.name === environmentEnums.LOCAL || environment.name === environmentEnums.DEVELOPMENT),
          transKey: 'SIDEBAR.WEB_BUSINESS_CARD',
          link: '/user/profile',
        },*/
      ],
    },
    {
      english: 'My Contacts',
      transKey: 'SIDEBAR.MY_CONTACTS',
      link: '',
      count: () => this.contactCount,
      subLinks: [
        {
          english: 'All Contacts',
          transKey: 'SIDEBAR.ALL_CONTACTS',
          link: '/contact/all',
        },
        /*  {
          english: 'Add',
          transKey: 'SIDEBAR.ADD',
          link: '/contact/add',
        },*/
        {
          english: 'Tags',
          transKey: 'SIDEBAR.TAGS',
          link: '/contact/tags',
        },
      ],
    },
    /*{
      english: 'Email Marketing',
      transKey: 'SIDEBAR.EMAIL_MARKETING',
      link: '',
      subLinks: [
        {
          english: 'E-Cards',
          transKey: 'SIDEBAR.ECARDS',
          link: '/email-marketing/ecards',
        },
        {
          english: 'Newsletter',
          hidden: !(environment.name === environmentEnums.LOCAL || environment.name === environmentEnums.DEVELOPMENT),
          transKey: 'SIDEBAR.NEWSLETTER',
          link: '/newsletter',
        },
      ],
    },*/
    {
      english: 'Coupons',
      transKey: 'SIDEBAR.COUPONS',
      link: '',
      subLinks: [
        {
          english: 'All Coupons',
          transKey: 'SIDEBAR.ALL_COUPONS',
          link: '/coupons/overview',
        },
        {
          english: 'Add Coupon',
          transKey: 'SIDEBAR.ADD_COUPON',
          link: '/coupons/editor',
        },
      ],
    } /*
    {
      english: 'Magazine',
      transKey: 'SIDEBAR.MAGAZINE',
      link: '',
      subLinks: [
        {
          english: 'Custom Landingpage',
          transKey: 'SIDEBAR.CUSTOM_LANDINGPAGE',
          link: '/landing-page/editorial',
        },
        {
          english: 'Highlights',
          transKey: 'SIDEBAR.HIGHLIGHTS',
          link: '/landing-page/highlights',
        },
      ],
    },*/,

    {
      english: 'Shop',
      transKey: 'SIDEBAR.SHOP',
      link: '',
      subLinks: [
        /*{
          english: 'Settings',
          transKey: 'SIDEBAR.SHOP_SETTINGS',
          link: '/shop-settings',
        },
        {
          english: 'Custom Landingpage',
          transKey: 'SIDEBAR.CUSTOM_LANDINGPAGE',
          link: '/shop-landing-page',
        },*/
        {
          english: 'Orders',
          transKey: 'SIDEBAR.ORDERS',
          link: '/shop/orders',
        },
        {
          english: 'Shop Reporting',
          transKey: 'SIDEBAR.SHOP_REPORTING',
          link: '/shop/analytics',
        } /*
        {
          english: 'Affiliate Link Picker',
          transKey: 'SIDEBAR.AFFILIATE_LINK_PICKER',
          link: '/shop/affiliate-link-picker',
        },*/,
      ],
    },
    /*
    {
      english: 'Social Media',
      hidden: !(environment.name === environmentEnums.LOCAL || environment.name === environmentEnums.DEVELOPMENT),
      transKey: 'SIDEBAR.SOCIAL_MEDIA',
      link: '/',
      subLinks: [
        {
          english: 'Facebook',
          transKey: 'SIDEBAR.FACEBOOK',
          link: '/',
        },
      ],
    },
    {
      english: 'Reporting',
      transKey: 'SIDEBAR.REPORTING',
      link: '',
      subLinks: [ 
        {
          english: 'E-Card Reporting',
          transKey: 'SIDEBAR.ECARD_REPORTING',
          link: '/ecard/report',
        },
        {
          english: 'Newsletter Reporting',
          hidden: !(environment.name === environmentEnums.LOCAL || environment.name === environmentEnums.DEVELOPMENT),
          transKey: 'SIDEBAR.NEWSLETTER_REPORTING',
          link: '/',
        },
        {
          english: 'Yours Reporting',
          transKey: 'SIDEBAR.YOURS_REPORTING',
          link: '/landing-page/analysis',
        },
      ],
    },*/ {
      english: 'Headquarter',
      transKey: 'SIDEBAR.HEADQUARTERS',
      onlyHeadquarter: true,
      link: '',
      subLinks: [
        {
          english: 'Overview',
          transKey: 'SIDEBAR.OVERVIEW',
          link: '/headquarter',
        },
        {
          english: 'Notification',
          transKey: 'SIDEBAR.NOTIFICATIONS',
          link: '/headquarter/notifications',
        },
        {
          english: 'Evaluations',
          transKey: 'SIDEBAR.EVALUATIONS',
          link: '/headquarter/evaluations',
        },
        {
          english: 'E-Card Builder',
          transKey: 'SIDEBAR.E_CARD_BUILDER',
          link: '/headquarter/e-card-builder/list',
        },
        {
          english: 'Mailing',
          transKey: 'SIDEBAR.MAILING',
          link: '/headquarter/mailing',
        },
        {
          english: 'Mailing Report',
          transKey: 'SIDEBAR.MAILING_REPORT',
          link: '/headquarter/mailing/report',
        },
        {
          english: 'Newsletter',
          hidden: !(environment.name === environmentEnums.LOCAL || environment.name === environmentEnums.DEVELOPMENT),
          transKey: 'SIDEBAR.NEWSLETTER_HEADQUARTER',
          link: '/headquarter/newsletter',
        },
      ],
    } /*
    {
      english: 'Help',
      transKey: 'HELP.TITLE',
      link: '/help',
    },*/,
  ];

  userRoles: string[];
  isAuthenticated$: Observable<boolean>;
  mobileMenuVisible: boolean;

  constructor(public facade: CoreStateFacade, public router: Router) {
    this.isAuthenticated$ = this.facade.isAuthenticated$;
  }

  get currentRoute() {
    return this.router.url;
  }

  ngOnInit() {
    this.facade.userRoles$.pipe(takeWhile(() => this.alive)).subscribe((roles) => {
      this.userRoles = roles;
    });
    this.facade.uiSidebarContactCount$
      .pipe(takeWhile(() => this.alive))
      .subscribe((count) => (this.contactCount = count));
    this.facade.mobileMenuState$
      .pipe(takeWhile(() => this.alive))
      .subscribe((state) => (this.mobileMenuVisible = state));
    this.setInitialNavState();
  }

  hasPermission(navPoint: INavPoint) {
    return !navPoint.onlyHeadquarter || this.isHeadquarter;
  }

  get isHeadquarter() {
    return this.userRoles.includes('hq');
  }

  get isBeta() {
    return this.userRoles.includes('beta');
  }

  hideMobileMenu() {
    if (this.mobileMenuVisible) this.facade.setUiMobileMenuHidden();
  }

  setInitialNavState() {
    let routePattern = new RegExp('');
    let openNavPoint = this.navStructure[0];
    let routeMatchFound = false;
    this.navStructure.forEach((navPoint: INavPoint) => {
      routePattern = new RegExp(navPoint.link + '$');
      if (navPoint.link && !routeMatchFound && routePattern.exec(this.currentRoute)) {
        routeMatchFound = true;
        openNavPoint = navPoint;
      }
      if (navPoint.subLinks) {
        navPoint.subLinks.forEach((subNavPoint: INavSubPoint) => {
          routePattern = new RegExp(subNavPoint.link + '$');
          if (subNavPoint.link && !routeMatchFound && routePattern.exec(this.currentRoute)) {
            routeMatchFound = true;
            openNavPoint = navPoint;
          }
        });
      }
    });
    openNavPoint.open = true;
  }

  toggle(clickedNavPoint: INavPoint) {
    if (!clickedNavPoint.subLinks) this.hideMobileMenu();
    if (clickedNavPoint.open) {
      clickedNavPoint.open = false;
      return;
    }
    /* first close all navPoints */
    this.navStructure.forEach((navPoint) => {
      navPoint.open = false;
    });
    /* then open the clicked one */
    clickedNavPoint.open = !clickedNavPoint.open;
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
