import { IUserSocialAccounts } from './user-social-accounts.interface';

export interface IUser extends IUserSocialAccounts {
  firstName: string;
  lastName: string;
  sharedName: string;
  fboId: string;
  fboAlias: string;
  profileImgUrl: string;
  email: string;
  phone: string;
  fax: string;
  gender: string;
  dob: string;
  ndpStatus: boolean;
  language: string;
  country: string;
  zipCode: string;
  city: string;
  street: string;
  yoursUrl: string;
  shopUrl: string;
  shopCountry: string;
  roles: string[];
  notifications?: IUserNotification[];
  acceptNewsletterConditions?: boolean;
  shopSettings?: IUserShopSettings;
  flags: {
    SLP_SYNC_INFO_HIDDEN: boolean;
  };
  discountMaxPercent: number;
}

export interface IUserDb {
  info: {
    firstName: string;
    lastName: string;
    sharedName: string;
    fboId: string;
    fboAlias: string;
    profileImgUrl: string;
    email: string;
    phone: string;
    fax: string;
    gender: string;
    birthdate: string;
    ndpStatus: boolean;
    language: string;
    country: string;
    zipCode: string;
    city: string;
    street: string;
    facebookAcc: string;
    youtubeAcc: string;
    linkedInAcc: string;
    xingAcc: string;
    twitterAcc: string;
    instagramAcc?: string;
    lpUrl: string;
    shopUrl: string;
    shopCountry: string;
  };
  roles: string[];
  notifications: IUserNotification[];
  acceptNewsletterConditions?: boolean;
  shopSettings?: IUserShopSettings;
  flags: {
    SLP_SYNC_INFO_HIDDEN: boolean;
  };
  discountMaxPercent: number;
}

export interface IUserNotification {
  _id: string;
  name: {
    de: string;
    at: string;
    ch: string;
    it: string;
    fr: string;
  };
}

export const mockUser: IUser = {
  firstName: 'David',
  lastName: 'Hunter',
  sharedName: 'huntard',
  fboId: '12345',
  fboAlias: 'hunter',
  profileImgUrl: '/profile/12345.jpg',
  email: 'david@hunter.com',
  phone: '234554656465',
  fax: '54634534645',
  gender: 'm',
  dob: '1984-02-16',
  ndpStatus: true,
  language: 'de',
  country: 'de',
  zipCode: '21431',
  city: 'London',
  street: 'Street',
  facebookAcc: '',
  linkedInAcc: '',
  xingAcc: '',
  twitterAcc: '',
  instagramAcc: '',
  youtubeAcc: '',
  yoursUrl: 'https://hunter.forever-yours.eu',
  shopUrl: 'https://be-forever.de/hunter',
  shopCountry: 'de',
  roles: ['user', 'test', 'headquarter', 'hq'],
  notifications: [
    {
      _id: 'asdasd',
      name: {
        de: 'de',
        at: 'at',
        ch: 'ch',
        it: 'it',
        fr: 'fr',
      },
    },
  ],
  acceptNewsletterConditions: false,
  flags: {
    SLP_SYNC_INFO_HIDDEN: false,
  },
  discountMaxPercent: 15,
};

export const initialUser: IUser = {
  firstName: '',
  lastName: '',
  sharedName: '',
  fboId: '',
  fboAlias: '',
  profileImgUrl: '',
  email: '',
  phone: '',
  fax: '',
  gender: '',
  dob: '',
  ndpStatus: true,
  language: '',
  country: '',
  zipCode: '',
  city: '',
  street: '',
  facebookAcc: '',
  linkedInAcc: '',
  youtubeAcc: '',
  xingAcc: '',
  twitterAcc: '',
  instagramAcc: '',
  shopUrl: 'https://be-forever.de',
  shopCountry: '',
  yoursUrl: 'https://forever-yours.de',
  roles: [],
  notifications: [],
  acceptNewsletterConditions: false,
  flags: {
    SLP_SYNC_INFO_HIDDEN: false,
  },
  discountMaxPercent: 15,
};

export interface IUserShopSettings {
  shippingCost: {
    DE: number | null;
    AT: number | null;
    CH: number | null;
  };
}
