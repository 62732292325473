import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ToastComponent } from './components/toast/toast.component';

@NgModule({
  declarations: [ToasterComponent, ToastComponent],
  imports: [CommonModule],
  exports: [ToasterComponent, ToastComponent],
})
export class ToasterModule {}
