<div id="app-container" class="app component">
    <div class="app__row app__row--top" [ngClass]="{'app__row--mobile': mobileMenuVisible$ | async}">
        <div class="row__header">
            <app-header *ngIf="uiVisible$ | async"></app-header>
        </div>
    </div>

    <div class="app__row app__row--bottom">
        <div class="row__sidebar"
                [ngClass]="{ 'row__sidebar--visible': mobileMenuVisible$ | async }">
            <app-sidebar
                *ngIf="uiVisible$ | async"
                ></app-sidebar>
        </div>
        <div class="row__content" [ngClass]="{ 'ui-hidden': !(uiVisible$ | async) }">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-messages id="app__messages"></app-messages>
</div>

