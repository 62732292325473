import { Component, OnInit } from '@angular/core';
import { CoreStateFacade } from '../../core-state.facade';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public languageSelection$: Observable<string>;
  public tiles: ITile[];
  public showPopup$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private stateFacade: CoreStateFacade) {}

  ngOnInit() {
    this.languageSelection$ = this.stateFacade.languageSelection$;
    this.tiles = [
      {
        name: 'contacts',
        alt: 'Contacts',
        classList: 'col-12 col-md-4 mb-5',
        routerLink: '/contact/all',
        src_en: '/assets/img/3contacts_en.jpg',
        src_de: '/assets/img/3contacts_de.jpg',
        src_it: '/assets/img/3contacts_it.jpg',
        src_fr: '/assets/img/3contacts_fr.jpg',
      },
    ];
  }
}

interface ITile {
  name: string;
  alt: string;
  classList: string;
  routerLink: string;
  src_en: string;
  src_de: string;
  src_it: string;
  src_fr: string;
}
