<ng-container *ngIf="(isAuthenticated$ | async)">
  <nav class="font-weight-light">
    <!-- list of navigation points START -->
    <ul>
      <ng-container *ngFor="let navPoint of navStructure">
        <li
          class="nav-point"
          [ngClass]="{ 'with-open-submenu': navPoint.open && navPoint.subLinks }"
          *ngIf="!navPoint.hidden && hasPermission(navPoint)"
        >
          <div class="nav-point__link" [routerLink]="navPoint.link || currentRoute" (click)="toggle(navPoint)">
            <span> {{ navPoint.transKey | translate }}</span>
            <div *ngIf="navPoint.count" class="badge">{{ navPoint.count() }}</div>
            <ng-container *ngIf="navPoint.subLinks">
              <i [ngClass]="{ 'i--sm--chevron-bold-up': navPoint.open, 'i--sm--chevron-bold-down': !navPoint.open }"></i>
            </ng-container>
          </div>

          <!-- list of navigation subpoints START -->
          <ul *ngIf="navPoint.subLinks && navPoint.open" class="sub-menu d-block">
            <ng-container *ngFor="let subLink of navPoint.subLinks">
              <li *ngIf="!subLink.hidden" class="sub-nav-point">
                <div [routerLink]="subLink.link" routerLinkActive="active-sublink" (click)="hideMobileMenu()">
                  <span translate>{{ subLink.transKey }}</span>
                  <div *ngIf="subLink.count" class="badge">{{ subLink.count() }}</div>
                </div>
              </li>
            </ng-container>
          </ul>
          <!-- list of navigation subpoints END -->
        </li>
      </ng-container>
    </ul>
    <!-- list of navigation points END -->
  </nav>
</ng-container>
