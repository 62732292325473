<div class="container-fluid h-100 w-100 component-container">
  <div class="row" style="justify-content: center">
    <div class="col-12">
      <h1 translate>{{ 'DASHBOARD.DASHBOARD_MAIN_TITLE' }}</h1>
    </div>
    <div class="col-12">
      <app-warning-panel [warningText]="'GENERIC_WARNINGS.GENERIC_SHUTDOWN'"></app-warning-panel>
      <br />
    </div>

    <div *ngFor="let tile of tiles" [class]="tile.classList">
      <a [routerLink]="tile.routerLink">
        <img
          *ngIf="(languageSelection$ | async) == 'en'"
          class="img-fluid"
          src="{{ tile.src_en }}"
          alt="{{ tile.alt }}"
        />
        <img
          *ngIf="(languageSelection$ | async) == 'de'"
          class="img-fluid"
          src="{{ tile.src_de }}"
          alt="{{ tile.alt }}"
        />
        <img
          *ngIf="(languageSelection$ | async) == 'it'"
          class="img-fluid"
          src="{{ tile.src_it }}"
          alt="{{ tile.alt }}"
        />
        <img
          *ngIf="(languageSelection$ | async) == 'fr'"
          class="img-fluid"
          src="{{ tile.src_fr }}"
          alt="{{ tile.alt }}"
        />
      </a>
    </div>
  </div>
</div>
