import { Component, Input, Output, EventEmitter } from '@angular/core';
import { fadeInOutFast } from '../../../../../../assets/animations/fade.animation';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: [fadeInOutFast],
})
export class PopupComponent {
  @Input() public title: string;
  @Input() public description: string;
  @Input() set visible(visible: boolean) {
    this._visible = visible;
  }
  @Output() closeClicked: EventEmitter<any> = new EventEmitter<any>();

  private _visible = false;

  constructor() {}

  get visible(): boolean {
    return this._visible;
  }

  closed() {
    this._visible = false;
    this.closeClicked.emit('clicked');
  }
}
