import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { IUser, IUserDb } from '../../models/user.interface';
import { JwtService } from '../jwt/jwt.service';
import { IUserSocialAccounts } from '../../models/user-social-accounts.interface';
import { IMongoResult } from '../../models/mongo-result.interface';

@Injectable()
export class UserService {
  private userId: string;

  constructor(private http: HttpClient, private jwt: JwtService) {
    this.userId = this.jwt.getUserIdFromToken();
  }

  getUser(): Observable<any> {
    this.userId = this.jwt.getUserIdFromToken();
    return this.http.get<any>(`${environment.cockpitApi}/user/${this.userId}`).pipe(
      map((user) => transformUserFromDb(user)),
      catchError((error: any) => observableThrowError(error.json())),
    );
  }

  getShopLpConfig(): Observable<any> {
    this.userId = this.jwt.getUserIdFromToken();
    return this.http.get<any>(`${environment.cockpitApi}/user/${this.userId}/shopLp`).pipe(
      map((result: any) => {
        if (!result.config) return null;
        return result.config;
      }),
      catchError((error: any) => {
        return observableThrowError(error.json());
      }),
    );
  }

  getUnreadUserNotifications(): Observable<any> {
    this.userId = this.jwt.getUserIdFromToken();
    return this.http
      .get<any>(`${environment.cockpitApi}/v2/user/notifications/unread`)
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  saveSlpConfig(config: any): Observable<any> {
    this.userId = this.jwt.getUserIdFromToken();
    config.updatedAt = Math.floor(Date.now() / 1000);
    return this.http
      .post<any>(`${environment.cockpitApi}/user/${this.userId}/shopLp`, { shopLp: config })
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getUserContactCount(): Observable<any> {
    this.userId = this.jwt.getUserIdFromToken();
    return this.http
      .get<any>(`${environment.cockpitApi}/contactcount/${this.userId}`)
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  updateUser(user: IUser): Observable<any> {
    this.userId = this.jwt.getUserIdFromToken();
    const userDb = transformUserToDb(user);
    return this.http.patch<any>(`${environment.cockpitApi}/user/${this.userId}`, userDb).pipe(
      map((resUser) => transformUserFromDb(resUser)),
      catchError((error: any) => observableThrowError(error.json())),
    );
  }

  updateProfileImg(img: string): Observable<any> {
    this.userId = this.jwt.getUserIdFromToken();
    const imgFile = b64toFile(img, 'image/png', 512);

    const body = new FormData();
    body.append('file', imgFile);

    return this.http.post<any>(`${environment.cockpitApi}/user/${this.userId}/profileImage`, body).pipe(
      map((resUser) => transformUserFromDb(resUser)),
      catchError((error: any) => observableThrowError(error.json())),
    );
  }

  loadUserSocialAccounts(): Observable<IUserSocialAccounts> {
    return this.http
      .get<IUserSocialAccounts>(`${environment.cockpitApi}/v2/user/social-media-update`)
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  updateUserFlag(name, value): Observable<IMongoResult | HttpErrorResponse> {
    return this.http
      .patch<IMongoResult>(`${environment.cockpitApi}/v2/user/flag`, { name, value })
      .pipe(catchError((error: HttpErrorResponse) => observableThrowError(error)));
  }
}

function transformUserToDb(user: IUser): IUserDb {
  const roles = user.roles;
  const lpUrl = user.yoursUrl;
  const birthdate = user.dob;
  const notifications = user.notifications;
  const acceptNewsletterConditions = user.acceptNewsletterConditions;

  const {
    firstName,
    lastName,
    sharedName,
    fboId,
    fboAlias,
    profileImgUrl,
    email,
    phone,
    fax,
    gender,
    ndpStatus,
    language,
    country,
    zipCode,
    city,
    street,
    facebookAcc,
    youtubeAcc,
    linkedInAcc,
    xingAcc,
    twitterAcc,
    instagramAcc,
    shopUrl,
    shopCountry,
    shopSettings,
    flags,
    discountMaxPercent,
  } = user;

  return {
    info: {
      firstName,
      lastName,
      sharedName,
      fboId,
      fboAlias,
      profileImgUrl,
      email,
      phone,
      fax,
      gender,
      birthdate,
      ndpStatus,
      language,
      country,
      zipCode,
      city,
      street,
      facebookAcc,
      youtubeAcc,
      linkedInAcc,
      xingAcc,
      twitterAcc,
      instagramAcc,
      lpUrl,
      shopUrl,
      shopCountry,
    },
    shopSettings,
    roles,
    notifications,
    acceptNewsletterConditions,
    flags,
    discountMaxPercent,
  };
}

function transformUserFromDb(user: IUserDb): IUser {
  const roles = user.roles;
  const yoursUrl = user.info.lpUrl;
  const dob = user.info.birthdate;
  const acceptNewsletterConditions = user.acceptNewsletterConditions;
  const notifications = user.notifications;
  const shopSettings = user.shopSettings;
  const flags = user.flags;
  const discountMaxPercent = user.discountMaxPercent;

  const {
    firstName,
    lastName,
    sharedName,
    fboId,
    fboAlias,
    email,
    phone,
    fax,
    gender,
    ndpStatus,
    zipCode,
    city,
    street,
    facebookAcc,
    youtubeAcc,
    linkedInAcc,
    xingAcc,
    twitterAcc,
    instagramAcc,
    profileImgUrl,
    language,
    country,
    shopUrl,
    shopCountry,
  } = user.info;

  return {
    firstName,
    lastName,
    sharedName,
    fboId,
    fboAlias,
    email,
    phone,
    fax,
    gender,
    dob,
    ndpStatus,
    zipCode,
    city,
    street,
    facebookAcc,
    youtubeAcc,
    linkedInAcc,
    xingAcc,
    twitterAcc,
    instagramAcc,
    profileImgUrl,
    language: language.toLowerCase(),
    country,
    shopUrl,
    shopCountry,
    yoursUrl,
    roles,
    acceptNewsletterConditions,
    shopSettings,
    flags,
    discountMaxPercent
  };
}

function b64toFile(b64Data: string, contentType: string, sliceSize: number): File | Blob {
  const byteCharacters = atob(b64Data.split(',')[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  try {
    return new File([blob], 'image' + contentType.split('/')[1], { type: contentType });
  } catch (err) {
    return blob;
  }
}
