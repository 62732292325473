import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtService } from '../../../core/services/index';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { ICondition } from '../models/condition.interface';
import { environment } from '../../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ConditionsService {
  constructor(private http: HttpClient, private jwt: JwtService) {}

  verify(): Observable<boolean> {
    return this.http
      .get<boolean>(`${environment.cockpitApi}/v2/conditions/verify`)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  loadUnacceptedConditions(): Observable<ICondition[]> {
    return this.http
      .get<ICondition[]>(`${environment.cockpitApi}/v2/conditions/unaccepted`)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  sendAcceptedConditions(conditionIds: string[]): Observable<any> {
    return this.http
      .patch<any>(`${environment.cockpitApi}/v2/conditions/accepted`, { conditionIds })
      .pipe(catchError((error: any) => observableThrowError(error)));
  }
}
