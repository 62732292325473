import { Action } from '@ngrx/store';

export const CLEAR_UI_STATE = '[ui] Clear UI State';

export const SET_UI_LOADING_TRUE = '[ui] Set UI Loading True';
export const SET_UI_LOADING_FALSE = '[ui] Set UI Loading False';

export const SET_UI_STATE_AUTHENTICATED = '[ui] Set UI State Authenticated';
export const SET_UI_STATE_DEAUTHENTICATED = '[ui] Set UI State Deauthenticated';

export const STORE_UI_LANGUAGE_SELECTION = '[ui] Store UI Language Selection in LS';
export const SET_UI_LANGUAGE_SELECTION = '[ui] Set UI Language Selection';

export const SET_MOBILE_MENU_VISIBLE = '[ui] Set UI Mobile Menu Visible';
export const SET_MOBILE_MENU_HIDDEN = '[ui] Set UI Mobile Menu Hidden';

export const SET_LEGACY_MODE_TRUE = '[ui] Set UI Legacy Mode True';
export const SET_LEGACY_MODE_FALSE = '[ui] Set UI Legacy Mode False';

export class ClearUIState implements Action {
  readonly type = CLEAR_UI_STATE;
}

export class SetUILoadingTrue implements Action {
  readonly type = SET_UI_LOADING_TRUE;
}

export class SetUILoadingFalse implements Action {
  readonly type = SET_UI_LOADING_FALSE;
}

export class SetUIMobileMenuVisible implements Action {
  readonly type = SET_MOBILE_MENU_VISIBLE;
}

export class SetUILegacyModeTrue implements Action {
  readonly type = SET_LEGACY_MODE_TRUE;
}

export class SetUILegacyModeFalse implements Action {
  readonly type = SET_LEGACY_MODE_FALSE;
}

export class SetUIMobileMenuHidden implements Action {
  readonly type = SET_MOBILE_MENU_HIDDEN;
}

export class SetUIStateAuthenticated implements Action {
  readonly type = SET_UI_STATE_AUTHENTICATED;
}

export class SetUIStateDeauthenticated implements Action {
  readonly type = SET_UI_STATE_DEAUTHENTICATED;
}

export class StoreUiLanguageSelection implements Action {
  readonly type = STORE_UI_LANGUAGE_SELECTION;
  constructor(public payload: string) {
  }
}

export class SetUiLanguageSelection implements Action {
  readonly type = SET_UI_LANGUAGE_SELECTION;
  constructor(public payload: string) {
  }
}

export type UIAction = ClearUIState
  | SetUILoadingTrue
  | SetUILoadingFalse
  | SetUIStateAuthenticated
  | SetUIStateDeauthenticated
  | StoreUiLanguageSelection
  | SetUiLanguageSelection
  | SetUIMobileMenuHidden
  | SetUIMobileMenuVisible
  | SetUILegacyModeFalse
  | SetUILegacyModeTrue;
