<!-- desktop -->
<div id="desktop-wrapper" [ngClass]="desktopToasterClassList">
  <ul *ngFor="let queue of desktopQueues" [ngClass]="queue?.classList">
    <li *ngFor="let toast of queue.toasts" [@create]="toast.animateIn" [@delete]="toast.animateOut">
      <app-toast [toast]="toast" (closed)="removeToast($event)"></app-toast>
    </li>
  </ul>
</div>

<!-- mobile -->
<div id="mobile-wrapper" [ngClass]="mobileToasterCLassList">
  <ul *ngFor="let queue of mobileQueues" [ngClass]="queue?.classList">
    <li *ngFor="let toast of queue.toasts" [@create]="toast.animateIn" [@delete]="toast.animateOut">
      <app-toast [toast]="toast" (closed)="removeToast($event)"></app-toast>
    </li>
  </ul>
</div>
