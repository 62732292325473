import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromUI from '../reducers/core-ui.reducer';

export const getUIState = createSelector(fromFeature.getCoreState, (state: fromFeature.CoreState) => state.ui);
export const getUIAuthenticated = createSelector(getUIState, fromUI.getUIAuthenticated);
export const getUILoading = createSelector(getUIState, fromUI.getUILoading);
export const getUILanguage = createSelector(getUIState, fromUI.getUILanguage);
export const getUIMobileMenuState = createSelector(getUIState, fromUI.getUIMobileMenuState);
export const getUILegacyModeState = createSelector(getUIState, fromUI.getUILegacyMode);
