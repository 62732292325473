import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { LegacyComponent } from './components/legacy/legacy.component';
import { CoreRoutingModule } from './core-routing.module';
import { StoreModule } from '@ngrx/store';
import { reducers, effects } from './store';
import * as fromServices from './services';
import { UserControlPanelComponent } from './components/header/user-control-panel/user-control-panel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreStateFacade } from './core-state.facade';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FormsModule } from '@angular/forms';
import { MessagesComponent } from './components/messages/messages.component';
import { InfoPanelModule } from '../shared-modules/info-panel/info-panel.module';
import { PopupModule } from '../shared-modules/popup/popup.module';
import { SafeModule } from '../shared-modules/safe-module/safe.module';
import { ToasterModule } from '../shared-modules/toaster/toaster.module';
import { UserControlPanelUtils } from './components/utils/user-control-panel.utils';
import { WarningPanelModule } from '../shared-modules/warning-panel/warning-panel.module';

@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule,
    TranslateModule,
    StoreModule.forFeature('core', reducers),
    EffectsModule.forFeature(effects),
    NgbModule,
    FormsModule,
    TranslateModule.forRoot(),
    InfoPanelModule,
    WarningPanelModule,
    PopupModule,
    SafeModule,
    ToasterModule,
  ],
  declarations: [
    SidebarComponent,
    HeaderComponent,
    LegacyComponent,
    UserControlPanelComponent,
    DashboardComponent,
    MessagesComponent,
  ],
  exports: [SidebarComponent, HeaderComponent, LegacyComponent, MessagesComponent],
  providers: [CoreStateFacade, ...fromServices.services, UserControlPanelUtils],
})
export class CoreModule {}
