import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs';
import { CoreStateFacade } from '../../modules/core/core-state.facade';
import { take, tap, switchMap, catchError } from 'rxjs/operators';
import { ConditionsService } from '../../modules/feature-modules/conditions/services';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store';

@Injectable({
  providedIn: 'root',
})
export class ConditionsGuard implements CanActivate {
  constructor(
    private stateFacade: CoreStateFacade, 
    private conditionsService: ConditionsService, 
    private router: Router,
    private store: Store<fromApp.AppState>,
    private coreFacade: CoreStateFacade,
    ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.stateFacade.userAcceptedConditions$.pipe(
      take(1),
      switchMap(userAcceptedConditions => {
        if (userAcceptedConditions) {
          return of(true).pipe(take(1));
        } else {
          return this.conditionsService.verify().pipe(
            //should the user not be allowed to check the conditions, he must be logged out
            catchError((error:any) => {
              if(error.status === 403){
                this.coreFacade.setUiStateDeauthenticated();
                this.store.dispatch(new fromApp.Go({ path: ['/user/login'], query: { returnTo: state.url } }));
              }
              return observableThrowError(error)
            }),
            take(1),
            tap(accepted => {
              if (!accepted) this.router.navigate(['/conditions']);
              else this.stateFacade.setUserAcceptedConditions(accepted);
            }),
          );
        }
      }),
    );
  }
}
