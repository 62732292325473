import { UserEffects } from './core-user.effects';
import { UiEffects } from './core-ui.effects';
import {FormsEffects} from "./core-forms.effects";
import { UISidebarEffects } from './core-ui-sidebar.effects';

export const effects: any[] = [UserEffects, UiEffects, UISidebarEffects, FormsEffects];

export * from './core-user.effects';
export * from './core-ui.effects';
export * from './core-forms.effects';
export * from './core-ui-sidebar.effects';
