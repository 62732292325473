import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromServices from '../../services';
import * as formsActions from '../actions/core-forms.actions';

@Injectable()
export class FormsEffects {
  constructor(
    private actions$: Actions,
    private formsService: fromServices.FormsService,
  ) {}

  @Effect()
  loadForms$ = this.actions$.pipe(ofType(formsActions.LOAD_FORMS))
    .pipe(
      switchMap(() => {
        return this.formsService.getForms().pipe(
          map(forms => {
            return new formsActions.LoadFormsSuccess(forms);
          }),
          catchError(error => {
            return of(new formsActions.LoadFormsFail(error));
          })
        );
      })
    );
}
