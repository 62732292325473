import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'user/login',
    loadChildren: () => import('./modules/feature-modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'user/pw-reset',
    loadChildren: () => import('./modules/feature-modules/pw-reset/pw-reset.module').then((m) => m.PwResetModule),
  },
  {
    path: 'dashboard/notifications',
    loadChildren: () =>
      import('./modules/feature-modules/user-notifications/user-notifications.module').then(
        (m) => m.UserNotificationsModule,
      ),
  },
  {
    path: 'user/profile',
    loadChildren: () =>
      import('./modules/feature-modules/user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./modules/feature-modules/contacts/contacts.module').then((m) => m.ContactsModule),
  },
  {
    loadChildren: () =>
      import('./modules/feature-modules/user-notifications/user-notifications.module').then(
        (m) => m.UserNotificationsModule,
      ),
    path: 'dashboard/notifications',
  },
  {
    path: 'shop/orders',
    loadChildren: () =>
      import('./modules/feature-modules/order-history/order-history.module').then((m) => m.OrderHistoryModule),
  },
  {
    path: 'shop/analytics',
    loadChildren: () =>
      import('./modules/feature-modules/shop-analytics/shop-analytics.module').then((m) => m.ShopAnalyticsModule),
  },
  {
    path: 'headquarter/notifications',
    loadChildren: () =>
      import('./modules/feature-modules/hq-notifications/hq-notifications.module').then((m) => m.HqNotificationsModule),
  },
  {
    path: 'headquarter/evaluations',
    loadChildren: () =>
      import('./modules/feature-modules/hq-evaluations/hq-evaluations.module').then((m) => m.HqEvaluationsModule),
  },
  {
    path: 'shop-landing-page',
    loadChildren: () =>
      import('./modules/feature-modules/shop-landing-page/shop-landing-page.module').then(
        (m) => m.ShopLandingPageModule,
      ),
  },
  {
    path: 'shop-settings',
    loadChildren: () =>
      import('./modules/feature-modules/shop-settings/shop-settings.module').then((m) => m.ShopSettingsModule),
  },
  /*{
    path: 'newsletter',
    loadChildren: () =>
      import('./modules/feature-modules/newsletter/newsletter.module').then((m) => m.NewsletterModule),
  },*/
  {
    path: 'optout',
    loadChildren: () => import('./modules/feature-modules/optout/optout.module').then((m) => m.OptoutModule),
  },
  {
    path: 'conditions',
    loadChildren: () =>
      import('./modules/feature-modules/conditions/conditions.module').then((m) => m.ConditionsModule),
  } /*
  {
    path: 'ecard-send',
    loadChildren: () => import('./modules/feature-modules/ecard-send/ecard-send.module').then((m) => m.EcardSendModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./modules/feature-modules/help/help.module').then((m) => m.HelpModule),
  },*/,
  {
    path: 'shop-landing-page',
    loadChildren: () =>
      import('./modules/feature-modules/shop-landing-page/shop-landing-page.module').then(
        (m) => m.ShopLandingPageModule,
      ),
  } /*
  {
    path: 'email-marketing',
    loadChildren: () =>
      import('./modules/feature-modules/email-marketing/email-marketing.module').then((m) => m.EmailMarketingModule),
  },
  {
    path: 'ecard',
    loadChildren: () =>
      import('./modules/feature-modules/email-marketing/email-marketing.module').then((m) => m.EmailMarketingModule),
  },*/,
  {
    path: 'coupons',
    loadChildren: () => import('./modules/feature-modules/coupons/coupons.module').then((m) => m.CouponsModule),
  },
  {
    path: 'shop/affiliate-link-picker',
    loadChildren: () =>
      import('./modules/feature-modules/affiliate-link-picker/affiliate-link-picker.module').then(
        (m) => m.AffiliateLinkPickerModule,
      ),
  },
  {
    path: 'headquarter/e-card-builder',
    loadChildren: () =>
      import('./modules/feature-modules/e-card-builder/e-card-builder.module').then((m) => m.ECardBuilderModule),
  },
  {
    path: 'headquarter/mailing',
    loadChildren: () => import('./modules/feature-modules/hq-mailing/hq-mailing.module').then((m) => m.HqMailingModule),
  },
  {
    path: 'headquarter/mailing/view',
    loadChildren: () => import('./modules/feature-modules/hq-mailing/hq-mailing.module').then((m) => m.HqMailingModule),
  },
  { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
