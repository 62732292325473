import { zip as observableZip } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CoreStateFacade } from '../../core-state.facade';
import { ICoreMessage } from '../../models/message.interface';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToasterComponent } from '../../../shared-modules/toaster/components/toaster/toaster.component';
import { IToast } from '../../../shared-modules/toaster/models/toast.interface';

interface IMessageTypeDefault {
  displayTime: number;
  iconClassList: string[];
  closeIconClassList: string[];
  animateIn: string;
  animateOut: string;
}

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit, AfterViewInit {
  @ViewChild(ToasterComponent, { static: true }) toaster: ToasterComponent;
  public alive = true;

  /* defaults */
  public defaultDisplayType = 'toast';
  public defaultDesktopPosition = 3;
  public defaultMobilePosition = 2;
  public errorDefaults = {
    displayTime: 0,
    iconClassList: ['i--lg--close-white--bg-circle', 'error-icon'],
    closeIconClassList: ['i--md--close-white'],
    animateIn: 'fade',
    animateOut: 'fade',
  };
  public warningDefaults = {
    displayTime: 0,
    iconClassList: ['i--lg--warning-white--bg-circle', 'warning-icon'],
    closeIconClassList: ['i--md--close-white'],
    animateIn: 'fade',
    animateOut: 'fade',
  };
  public successDefaults = {
    displayTime: 2500,
    iconClassList: ['i--lg--check-white--bg-circle', 'success-icon'],
    closeIconClassList: ['i--md--close-white'],
    animateIn: 'fade',
    animateOut: 'fade',
  };

  constructor(public stateFacade: CoreStateFacade, public translate: TranslateService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.stateFacade.successMessages$.pipe(takeWhile(() => this.alive)).subscribe(messages => {
      if (messages.length > 0) {
        this.addMessage(messages[messages.length - 1], 'success');
      } else {
        this.removeMessages('success');
      }
    });

    this.stateFacade.warningMessages$.pipe(takeWhile(() => this.alive)).subscribe(messages => {
      if (messages.length > 0) {
        this.addMessage(messages[messages.length - 1], 'warning');
      } else {
        this.removeMessages('warning');
      }
    });

    this.stateFacade.errorMessages$.pipe(takeWhile(() => this.alive)).subscribe(messages => {
      if (messages.length > 0) {
        this.addMessage(messages[messages.length - 1], 'error');
      } else {
        this.removeMessages('error');
      }
    });
  }

  public addMessage(message: ICoreMessage, type: string) {
    const consistentMessage: ICoreMessage = this.getConsistentMessage(message, type);
    switch (consistentMessage.data.display.type) {
      case 'toast':
        this.createToast(consistentMessage, type);
        break;
    }
  }

  public removeMessages(type: string): void {
    this.toaster.removeByClassName(type);
  }

  public createToast(message: ICoreMessage, type: string): void {
    observableZip(this.translate.get(type.toUpperCase()), this.translate.get(message.message), (title: string, body: string) => ({
      title,
      body,
    }))
      .pipe(take(1))
      .subscribe(translations => {
        const toast: IToast = {
          classList: [type, ...message.data.display.classList],
          title: translations.title,
          body: translations.body,
          desktopPos: message.data.display.desktopPosition,
          mobilePos: message.data.display.mobilePosition,
          displayTime: message.data.display.displayTime,
          iconClassList: message.data.display.iconClassList,
          closeIconClassList: message.data.display.closeIconClassList,
          animateIn: message.data.display.animateIn,
          animateOut: message.data.display.animateOut,
        };
        this.toaster.addToast(toast);
      });
  }

  public getConsistentMessage(message: ICoreMessage, type: string): ICoreMessage {
    const returnMessage: ICoreMessage = {
      message: message.message,
      data: {
        display: {
          type: this.defaultDisplayType,
          classList: [],
          displayTime: this.getDefaults(type).displayTime,
          iconClassList: this.getDefaults(type).iconClassList,
          closeIconClassList: this.getDefaults(type).closeIconClassList,
          desktopPosition: this.defaultDesktopPosition,
          mobilePosition: this.defaultMobilePosition,
          animateIn: this.getDefaults(type).animateIn,
          animateOut: this.getDefaults(type).animateOut,
        },
      },
    };
    if (_.has(message, 'data.display')) {
      returnMessage.data.display = {
        ...returnMessage.data.display,
        ...message.data.display,
      };
    }
    return returnMessage;
  }

  public getDefaults(messageType: string): IMessageTypeDefault {
    if (messageType === 'error') {
      return this.errorDefaults;
    }
    if (messageType === 'warning') {
      return this.warningDefaults;
    }
    if (messageType === 'success') {
      return this.successDefaults;
    }
  }
}
