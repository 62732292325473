import { Injectable } from '@angular/core';

@Injectable()
export class UserControlPanelUtils {
  constructor() {}

  public getUNNotificationTranslationFromUiLanguage(language: string): string {
    switch (language) {
      case 'de':
        return 'de';
      case 'fr':
        return 'ch_fr';
      case 'it':
        return 'ch_it';
      default:
        return 'de';
    }
  }
}
