
import {takeWhile} from 'rxjs/operators';
import {AfterViewInit, Component, OnInit, OnDestroy} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-legacy',
  templateUrl: './legacy.component.html',
  styleUrls: ['./legacy.component.scss']
})
export class LegacyComponent implements OnInit, AfterViewInit, OnDestroy {
  page = '/dashboard';
  alive = true;
  iframeSrc = 'assets/legacyCockpit/index.html#dashboard';
  /**
   * All subroutes need a trailing slash in order to work (for instance
   * ecard/edit/44324343XXXX/) but most of the other routes in the legacy
   * cockpit won't work with a trailing slash. If no trailing slash is allowed,
   * the route should be listed in this array.
   */
  noSlashSuffixRoutes = ['/ecard$', '/newsletter', '/contact', '/contact/import', '/contact/add',
    '/landing-page/editorial', '/landing-page/highlights', '/landing-page/analysis', '/ecard/report', '/headquarter',
    '/headquarter/templates/detail', '/headquarter/mailing/report', '/headquarter/newsletter',
    '/headquarter/notification', '/dashboard/notifications', '/headquarter/templates/.+', '/unsubscribe/.+'];

  constructor(public sanitizer: DomSanitizer, public router: Router) {
  }

  ngOnInit() {
    this.page = this.maybeWithTrailingSlash(this.router.url);
    this.iframeSrc = this.iframeSrcFromRoute(this.page);
    this.router.events.pipe(takeWhile(() => this.alive)).subscribe(value => {
      this.iframeSrc = this.iframeSrcFromRoute((<any>value).url);
        window.history.pushState({html: '', title: ''}, '', (<any>value).url);
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.alive = false;
  }

  maybeWithTrailingSlash(url) {
    if (!url) return;
    // regex matching
    let matchFound = false;
    this.noSlashSuffixRoutes.forEach(noSlashRoute => {
      const pattern = new RegExp(noSlashRoute);
      if (pattern.exec(url)) matchFound = true;
    });

    if (!matchFound && url[url.length - 1] !== '/') {
      url = url + '/';
    }
    return url;
  }

  iframeSrcFromRoute(currentRoute) {
    currentRoute = this.maybeWithTrailingSlash(currentRoute);
    const iframeSrc = 'assets/legacyCockpit/index.html#' + currentRoute;
    return iframeSrc;
  }

  addClickEventListener() {
    const iframe = <HTMLIFrameElement> document.getElementById('legacy');
    iframe.contentWindow.document.body.addEventListener('click', (event) => {
      this.syncUrlBar(event);
    });
  }

  syncUrlBar(event) {
    if (!event.target) return;
    let newIframeRoute = '';
    if (!event.target.hash) {
      const newIframeUrl = event.target.baseURI;
      const regex = new RegExp('index.html(#.+)', 'g');
      newIframeRoute = regex.exec(newIframeUrl)[1];
    } else {
      newIframeRoute = event.target.hash;
    }
    const newUrl = environment.protocol + '://' + window.location.host + newIframeRoute;
    window.history.pushState({html: '', title: ''}, '', newUrl);
    this.page = newIframeRoute;
    this.iframeSrcFromRoute(newIframeRoute);
  }

}
