import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromUser from '../reducers/core-user.reducer';

export const getUserState = createSelector(fromFeature.getCoreState, (state: fromFeature.CoreState) => state.user);
export const getUserData = createSelector(getUserState, fromUser.getUser);
export const getUserLoading = createSelector(getUserState, fromUser.getUserLoading);
export const getUserLoaded = createSelector(getUserState, fromUser.getUserLoaded);
export const getUserNotifications = createSelector(getUserState, fromUser.getUserNotifications);
export const getUserRoles = createSelector(getUserState, fromUser.getUserRoles);
export const getUserLanguage = createSelector(getUserState, fromUser.getUserLanguage);
export const getUserCountry = createSelector(getUserState, fromUser.getUserCountry);
export const getUserAcceptedConditions = createSelector(getUserState, fromUser.getUserAcceptedConditions);
export const getUserFlags = createSelector(getUserState, fromUser.getUserFlags);
