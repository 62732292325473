import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreStateFacade } from './modules/core/core-state.facade';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2 } from 'angulartics2';
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  mobileMenuVisible$: Observable<boolean>;
  authenticated$: Observable<boolean>;
  acceptedConditions$: Observable<boolean>;

  constructor(
    public translate: TranslateService,
    public stateFacade: CoreStateFacade,
    public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    public angulartics2: Angulartics2,
    private router: Router,
  ) {}

  ngOnInit() {
    this.angulartics2GoogleAnalytics.startTracking();
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.angulartics2GoogleAnalytics.eventTrack('page', { pageName: item.url });
      }
    });
    this.translate.setDefaultLang('de');
    this.stateFacade.uiLanguage$.subscribe((language: string) => {
      this.translate.use(language);
    });

    this.mobileMenuVisible$ = this.stateFacade.mobileMenuState$;
    this.authenticated$ = this.stateFacade.isAuthenticated$;
    this.acceptedConditions$ = this.stateFacade.userAcceptedConditions$;
  }

  get uiVisible$() {
    return combineLatest(this.authenticated$, this.acceptedConditions$).pipe(
      map(([authenticated, acceptedConditions]) => authenticated && acceptedConditions),
    );
  }
}
