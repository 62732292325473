import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const GO = '[Router] Go';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';
export const REDIRECT = '[Router] Redirect';

export class Go implements Action {
  readonly type = GO;
  constructor(
    public payload: {
      path: string[];
      query?: object;
      extras?: NavigationExtras;
    },
  ) {}
}

export class Redirect implements Action {
  readonly type = REDIRECT;
  constructor(
    public payload: {
      path: string;
      timeout?: number;
    },
  ) {}
}

export class Back implements Action {
  readonly type = BACK;
}

export class Forward implements Action {
  readonly type = FORWARD;
}

export type Actions = Go | Back | Forward;
