import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LegacyComponent } from './components/legacy/legacy.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import * as fromAppGuards from '../../guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/dashboard',
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  {
    path: 'landing-page',
    redirectTo: 'landing-page/editorial',
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  {
    path: 'landing-page/editorial',
    component: LegacyComponent,
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  {
    path: 'landing-page/highlights',
    component: LegacyComponent,
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  {
    path: 'landing-page/analysis',
    component: LegacyComponent,
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  { path: 'order', component: LegacyComponent, canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard] },
  {
    path: 'order/shop-reporting',
    redirectTo: '/shop-reporting',
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  {
    path: 'shop-reporting',
    component: LegacyComponent,
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  {
    path: 'ecard/report',
    component: LegacyComponent,
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  {
    path: 'ecard/report/single/:ecardId',
    component: LegacyComponent,
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  {
    path: 'headquarter',
    component: LegacyComponent,
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
    children: [
      { path: 'templates', component: LegacyComponent, children: [{ path: '**', component: LegacyComponent }] },
    ],
  },
  {
    path: 'headquarter/mailing/report',
    component: LegacyComponent,
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  {
    path: 'headquarter/newsletter',
    component: LegacyComponent,
    canActivate: [fromAppGuards.AuthGuard, fromAppGuards.ConditionsGuard],
  },
  {
    path: 'newsletter/preview',
    component: LegacyComponent,
    canActivate: [fromAppGuards.AuthGuard],
    children: [{ path: '**', component: LegacyComponent }],
  },
  {
    path: 'unsubscribe',
    component: LegacyComponent, // Unsubscribe Route for legacy links
    children: [{ path: '**', component: LegacyComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
