import { Go } from './../../store/actions/router.action';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromCoreState from './store';
import * as fromRouterState from '../../store';
import { Observable } from 'rxjs';
import { IUser, IUserNotification } from './models/user.interface';
import { ILanguage } from './models/language.interface';
import { ICountry } from './models/country.interface';
import { ICoreMessage } from './models/message.interface';
import { IGender } from './models/gender.interface';
import { IDiscount } from './models/discount.interface';
import { NavigationExtras } from '@angular/router';

@Injectable()
export class CoreStateFacade {
  private _user$: Observable<IUser>;
  private _userLoading$: Observable<boolean>;
  private _userLoaded$: Observable<boolean>;
  private _userAcceptedConditions$: Observable<boolean>;
  private _languageSelection$: Observable<string>;
  private _isAuthenticated$: Observable<boolean>;
  private _userRoles$: Observable<string[]>;
  private _mobileMenuState$: Observable<boolean>;
  private _legacyMode$: Observable<boolean>;
  private _userLanguage$: Observable<string>;
  private _uiLanguage$: Observable<string>;
  private _userCountry$: Observable<string>;
  private _userFlags$: Observable<IUser['flags']>;
  private _errorMessages$: Observable<ICoreMessage[]>;
  private _userUnreadNotifications$: Observable<IUserNotification[]>;
  private _warningMessages$: Observable<ICoreMessage[]>;
  private _successMessages$: Observable<ICoreMessage[]>;
  private _uiSidebarContactCount$: Observable<number>;
  private _countryChoices$: Observable<ICountry[]>;
  private _languageChoices$: Observable<ILanguage[]>;
  private _genderChoices$: Observable<IGender[]>;
  private _discountChoices$: Observable<IDiscount[]>;

  constructor(private store: Store<fromCoreState.CoreState>) {
    this._user$ = store.select(fromCoreState.getUserData);
    this._userLoading$ = store.select(fromCoreState.getUserLoading);
    this._userLoaded$ = store.select(fromCoreState.getUserLoaded);
    this._userAcceptedConditions$ = store.select(fromCoreState.getUserAcceptedConditions);
    this._userRoles$ = store.select(fromCoreState.getUserRoles);
    this._languageSelection$ = store.select(fromCoreState.getUILanguage);
    this._isAuthenticated$ = store.select(fromCoreState.getUIAuthenticated);
    this._userUnreadNotifications$ = store.select(fromCoreState.getUserNotifications);
    this._mobileMenuState$ = store.select(fromCoreState.getUIMobileMenuState);
    this._legacyMode$ = store.select(fromCoreState.getUILegacyModeState);
    this._userLanguage$ = store.select(fromCoreState.getUserLanguage);
    this._uiLanguage$ = store.select(fromCoreState.getUILanguage);
    this._userCountry$ = store.select(fromCoreState.getUserCountry);
    this._userFlags$ = store.select(fromCoreState.getUserFlags);
    this._errorMessages$ = store.select(fromCoreState.getErrorMessages);
    this._warningMessages$ = store.select(fromCoreState.getWarningMessages);
    this._successMessages$ = store.select(fromCoreState.getSuccessMessages);
    this._uiSidebarContactCount$ = store.select(fromCoreState.getUISidebarContactCount);
    this._languageChoices$ = store.select(fromCoreState.getFormsLanguageChoices);
    this._countryChoices$ = store.select(fromCoreState.getFormsCountryChoices);
    this._genderChoices$ = store.select(fromCoreState.getFormsGenderChoices);
    this._discountChoices$ = store.select(fromCoreState.getFormsDiscountChoices);
  }

  get user$(): Observable<IUser> {
    return this._user$;
  }

  get userLoading$(): Observable<boolean> {
    return this._userLoading$;
  }

  get userAcceptedConditions$(): Observable<boolean> {
    return this._userAcceptedConditions$;
  }

  get mobileMenuState$(): Observable<boolean> {
    return this._mobileMenuState$;
  }

  get legacyMode$(): Observable<boolean> {
    return this._legacyMode$;
  }

  get userLoaded$(): Observable<boolean> {
    return this._userLoaded$;
  }

  get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated$;
  }

  get userRoles$(): Observable<string[]> {
    return this._userRoles$;
  }

  get userUnreadNotifications$(): Observable<IUserNotification[]> {
    return this._userUnreadNotifications$;
  }

  get languageSelection$(): Observable<string> {
    return this._languageSelection$;
  }

  get userLanguage$(): Observable<string> {
    return this._userLanguage$;
  }

  get uiLanguage$(): Observable<string> {
    return this._uiLanguage$;
  }

  get userCounty$(): Observable<string> {
    return this._userCountry$;
  }

  get userFlags$(): Observable<IUser['flags']> {
    return this._userFlags$;
  }

  get errorMessages$(): Observable<ICoreMessage[]> {
    return this._errorMessages$;
  }

  get warningMessages$(): Observable<ICoreMessage[]> {
    return this._warningMessages$;
  }

  get successMessages$(): Observable<ICoreMessage[]> {
    return this._successMessages$;
  }

  get uiSidebarContactCount$(): Observable<number> {
    return this._uiSidebarContactCount$;
  }

  get languageChoices$(): Observable<ILanguage[]> {
    return this._languageChoices$;
  }

  get countryChoices$(): Observable<ICountry[]> {
    return this._countryChoices$;
  }

  get genderChoices$(): Observable<IGender[]> {
    return this._genderChoices$;
  }

  get discountChoices$(): Observable<IDiscount[]> {
    return this._discountChoices$;
  }

  public clearUserState(): void {
    this.store.dispatch(new fromCoreState.ClearUserState());
  }

  public clearUIState(): void {
    this.store.dispatch(new fromCoreState.ClearUIState());
  }

  public setUiStateAuthenticated(): void {
    this.store.dispatch(new fromCoreState.SetUIStateAuthenticated());
  }

  public setUiStateDeauthenticated(): void {
    this.store.dispatch(new fromCoreState.SetUIStateDeauthenticated());
  }

  public setUserAcceptedConditions(acceptedConditions: boolean): void {
    this.store.dispatch(new fromCoreState.SetUserAcceptedConditions(acceptedConditions));
  }

  public setUiMobileMenuVisible(): void {
    this.store.dispatch(new fromCoreState.SetUIMobileMenuVisible());
  }

  public setUiMobileMenuHidden(): void {
    this.store.dispatch(new fromCoreState.SetUIMobileMenuHidden());
  }

  public setUiLegacyModeFalse(): void {
    this.store.dispatch(new fromCoreState.SetUILegacyModeFalse());
  }

  public setUiLegacyModeTrue(): void {
    this.store.dispatch(new fromCoreState.SetUILegacyModeTrue());
  }

  public loadUser(): void {
    this.store.dispatch(new fromCoreState.LoadUser());
  }

  public updateUserFlag(name, value): void {
    this.store.dispatch(new fromCoreState.UpdateUserFlag({ name, value }));
  }

  public storeLanguage(language: string): void {
    this.store.dispatch(new fromCoreState.StoreUiLanguageSelection(language));
  }

  public clearMessages(): void {
    this.store.dispatch(new fromCoreState.ClearMessages());
  }

  public addErrorMessage(message: ICoreMessage): void {
    this.store.dispatch(new fromCoreState.AddErrorMessage(message));
  }

  public addWarningMessage(message: ICoreMessage): void {
    this.store.dispatch(new fromCoreState.AddWarningMessage(message));
  }

  public addSuccessMessage(message: ICoreMessage): void {
    this.store.dispatch(new fromCoreState.AddSuccessMessage(message));
  }

  public updateUserData(user: IUser): void {
    this.store.dispatch(new fromCoreState.UpdateUserData(user));
  }

  public updateProfileImg(img: any): void {
    this.store.dispatch(new fromCoreState.UpdateProfileImg(img));
  }

  public loadForms(): void {
    this.store.dispatch(new fromCoreState.LoadForms());
  }

  public loadUserSocialAccounts(): void {
    this.store.dispatch(new fromCoreState.LoadUserSocialAccounts());
  }

  public goToRoute(options: { path: string[]; query?: object; extras?: NavigationExtras }): void {
    this.store.dispatch(new fromRouterState.Go(options));
  }
}
