import { throwError as observableThrowError, Observable, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class FormsService {
  constructor(private http: HttpClient) {}

  getForms(): Observable<any> {
    const countryChoices$ = this.http.get<any>(`${environment.cockpitApi}/country/choices`).pipe(
      map(countries => {
        countries.forEach(country => {
          delete country._id;
        });
        return countries;
      }),
      catchError((error: any) => observableThrowError(error)),
    );

    const languageChoices$ = this.http.get<any>(`${environment.cockpitApi}/language/choices`).pipe(
      map(languages => {
        languages.forEach(language => {
          delete language._id;
        });
        return languages;
      }),
      catchError((error: any) => observableThrowError(error)),
    );

    const genderChoices$ = this.http.get<any>(`${environment.cockpitApi}/gender/choices`).pipe(
      map(genders => {
        genders.forEach(gender => {
          delete gender._id;
        });
        return genders;
      }),
      catchError((error: any) => observableThrowError(error)),
    );

    const discountChoices$ = this.http.get<any>(`${environment.cockpitApi}/v2/discount/choices`).pipe(
      map(discounts => {
        discounts.forEach(discount => {
          delete discount._id;
        });
        return discounts;
      }),
      catchError((error: any) => observableThrowError(error)),
    );

    return combineLatest(
      countryChoices$,
      languageChoices$,
      genderChoices$,
      discountChoices$,
      (countryChoices, languageChoices, genderChoices, discountChoices) => {
        return { countryChoices, languageChoices, genderChoices, discountChoices };
      },
    );
  }
}
