import { Component, OnInit } from '@angular/core';
import { CoreStateFacade } from '../../core-state.facade';
import { Observable } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isVisible = false;
  _alive: boolean;

  constructor(public facade: CoreStateFacade) {
    this._alive = true;
    this.facade.mobileMenuState$.pipe(takeWhile(() => this._alive)).subscribe((isVisible) => {
      this.isVisible = isVisible;
    });
  }

  ngOnInit() {}

  toggleMobileMenu(isVisible: boolean): void {
    if (isVisible) {
      this.facade.setUiMobileMenuHidden();
    } else {
      this.facade.setUiMobileMenuVisible();
    }
  }

  get showMobile(): Observable<boolean> {
    return this.facade.mobileMenuState$;
  }

  ngOnDestory() {
    this._alive = false;
  }
}
