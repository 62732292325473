import { Component, Input } from '@angular/core';
import { IToast } from '../../models/toast.interface';
import { IToastQueue } from '../../models/toast-queue.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  animations: [
    trigger('create', [
      state('fade', style({ opacity: 1 })),
      state('pop', style({ transform: 'scale(1)', opacity: 1 })),
      transition('void => fade', [style({ opacity: 0 }), animate('500ms ease-in')]),
      transition('void => pop', [style({ transform: 'scale(0.1)', opacity: 0 }), animate('200ms ease-in')]),
    ]),
    trigger('delete', [
      state('fade', style({ opacity: 1 })),
      state('pop', style({ transform: 'scale(1)', opacity: 1 })),
      transition('fade => void', [animate('500ms ease-in', style({ opacity: 0 }))]),
      transition('pop => void', [animate('200ms ease-in', style({ transform: 'scale(0.1)', opacity: 0 }))]),
    ]),
  ],
})
export class ToasterComponent {
  @Input() desktopToasterClassList: string | string[];
  @Input() mobileToasterCLassList: string | string[];

  public desktopQueues: IToastQueue[];
  public mobileQueues: IToastQueue[];

  constructor() {
    this.initDesktopQueues();
    this.initMobileQueues();
  }

  /*--- init ---*/
  public initDesktopQueues(): void {
    this.desktopQueues = [
      {
        toasts: [],
        classList: ['toaster--top-left'],
      },
      {
        toasts: [],
        classList: ['toaster--top-center'],
      },
      {
        toasts: [],
        classList: ['toaster--top-right'],
      },
      {
        toasts: [],
        classList: ['toaster--bottom-left'],
      },
      {
        toasts: [],
        classList: ['toaster--bottom-center'],
      },
      {
        toasts: [],
        classList: ['toaster--bottom-right'],
      },
    ];
  }

  public initMobileQueues(): void {
    this.mobileQueues = [
      {
        toasts: [],
        classList: ['toaster--mobile--top'],
      },
      {
        toasts: [],
        classList: ['toaster--mobile--bottom'],
      },
    ];
  }

  /*--- add toast ---*/
  public addToast(toast: IToast): void {
    toast.id = toast.id ? toast.id : _.uniqueId();
    this.addToastDesktop(toast);
    this.addToastMobile(toast);
  }

  public addToastDesktop(toast: IToast): void {
    switch (toast.desktopPos) {
      case 1:
        this.desktopQueues[0].toasts.push(toast);
        break;
      case 2:
        this.desktopQueues[1].toasts.push(toast);
        break;
      case 3:
        this.desktopQueues[2].toasts.push(toast);
        break;
      case 4:
        this.desktopQueues[3].toasts.push(toast);
        break;
      case 5:
        this.desktopQueues[4].toasts.push(toast);
        break;
      case 6:
        this.desktopQueues[5].toasts.push(toast);
    }
  }

  public addToastMobile(toast: IToast): void {
    switch (toast.mobilePos) {
      case 1:
        this.mobileQueues[0].toasts.push(toast);
        break;
      case 2:
        this.mobileQueues[1].toasts.push(toast);
    }
  }

  /*--- remove toast ---*/
  public removeToast(toast: IToast): void {
    this.removeDesktopToast(toast);
    this.removeMobileToast(toast);
  }

  public removeDesktopToast(toast: IToast): void {
    const toasts = this.desktopQueues[toast.desktopPos - 1].toasts;
    toasts.forEach((element: IToast, index) => {
      if (toasts[index].id === toast.id) {
        toasts.splice(index, 1);
      }
    });
  }

  public removeMobileToast(toast: IToast): void {
    const toasts = this.mobileQueues[toast.mobilePos - 1].toasts;
    toasts.forEach((element: IToast, index) => {
      if (toasts[index].id === toast.id) {
        toasts.splice(index, 1);
      }
    });
  }

  public removeByClassName(className: string): void {
    this.removeDesktopByClassName(className);
    this.removeMobileByClassName(className);
  }

  public removeDesktopByClassName(className: string): void {
    this.desktopQueues.forEach(queue => {
      queue.toasts.forEach((toast: IToast) => {
        if (toast.classList.includes(className)) this.removeDesktopToast(toast);
      });
    });
  }

  public removeMobileByClassName(className: string): void {
    this.mobileQueues.forEach(queue => {
      queue.toasts.forEach((toast: IToast) => {
        if (toast.classList.includes(className)) this.removeMobileToast(toast);
      });
    });
  }

  /*--- clear toasts ---*/
  public clearToasts(): void {
    this.clearDesktopToasts();
    this.clearMobileToasts();
  }

  public clearDesktopToasts(): void {
    this.initDesktopQueues();
  }

  public clearMobileToasts(): void {
    this.initMobileQueues();
  }
}
