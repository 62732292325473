import { Action } from '@ngrx/store';
import { IUserNotification } from '../../models/user.interface';
import { IUserSocialAccounts } from '../../models/user-social-accounts.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { IMongoResult } from './../../models/mongo-result.interface';

export const CLEAR_USER_STATE = '[core] Clear User State';

export const LOAD_USER = '[core] Load User';

export const LOAD_USER_DATA = '[core] Load User Data';
export const LOAD_USER_DATA_FAIL = '[core] Load User Data Fail';
export const LOAD_USER_DATA_SUCCESS = '[core] Load User Data Success';

export const UPDATE_USER_DATA = '[core] Try to Update User Data';
export const UPDATE_USER_DATA_FAIL = '[core] Update User Data Fail';
export const UPDATE_USER_DATA_SUCCESS = '[core] Update User Data Success';

export const UPDATE_PROFILE_IMG = '[core] Try to Update Profile Image';
export const UPDATE_PROFILE_IMG_FAIL = '[core] Update Profile Image Fail';
export const UPDATE_PROFILE_IMG_SUCCESS = '[core] Update Profile Image Success';

export const LOAD_USER_NOTIFICATIONS = '[core] Load User Notifications';
export const LOAD_USER_NOTIFICATIONS_FAIL = '[core] Load User Notifications Fail';
export const LOAD_USER_NOTIFICATIONS_SUCCESS = '[core] Load User Notifications Success';

export const SET_USER_ACCEPTED_CONDITIONS = '[core/user] Set User Accepted Conditions';

export const LOAD_USER_SOCIAL_ACCOUNTS = '[core] Try to Update User Social Accounts';
export const LOAD_USER_SOCIAL_ACCOUNTS_FAIL = '[core] Update User Social Accounts Fail';
export const LOAD_USER_SOCIAL_ACCOUNTS_SUCCESS = '[core] Update User Social Accounts Success';

export const UPDATE_USER_FLAG = '[core] Try to Update User Flag';
export const UPDATE_USER_FLAG_FAIL = '[core] Update User Flag Fail';
export const UPDATE_USER_FLAG_SUCCESS = '[core] Update User Flag Success';

export class ClearUserState implements Action {
  readonly type = CLEAR_USER_STATE;
}

export class LoadUser implements Action {
  readonly type = LOAD_USER;
}

export class LoadUserData implements Action {
  readonly type = LOAD_USER_DATA;
}

export class LoadUserDataFail implements Action {
  readonly type = LOAD_USER_DATA_FAIL;
  constructor(public payload: any) {}
}

export class LoadUserDataSuccess implements Action {
  readonly type = LOAD_USER_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateUserData implements Action {
  readonly type = UPDATE_USER_DATA;
  constructor(public payload: any) {}
}

export class UpdateUserDataFail implements Action {
  readonly type = UPDATE_USER_DATA_FAIL;
  constructor(public payload: any) {}
}

export class UpdateUserDataSuccess implements Action {
  readonly type = UPDATE_USER_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateProfileImg implements Action {
  readonly type = UPDATE_PROFILE_IMG;
  constructor(public payload: any) {}
}

export class UpdateProfileImgFail implements Action {
  readonly type = UPDATE_PROFILE_IMG_FAIL;
  constructor(public payload: any) {}
}

export class UpdateProfileImgSuccess implements Action {
  readonly type = UPDATE_PROFILE_IMG_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadUserNotifications implements Action {
  readonly type = LOAD_USER_NOTIFICATIONS;
}

export class LoadUserNotificationsFail implements Action {
  readonly type = LOAD_USER_NOTIFICATIONS_FAIL;
  constructor(public payload: any) {}
}

export class LoadUserNotificationsSuccess implements Action {
  readonly type = LOAD_USER_NOTIFICATIONS_SUCCESS;
  constructor(public payload: IUserNotification[]) {}
}

export class SetUserAcceptedConditions implements Action {
  readonly type = SET_USER_ACCEPTED_CONDITIONS;
  constructor(public payload: boolean) {}
}

export class LoadUserSocialAccounts implements Action {
  readonly type = LOAD_USER_SOCIAL_ACCOUNTS;
}

export class LoadUserSocialAccountsFail implements Action {
  readonly type = LOAD_USER_SOCIAL_ACCOUNTS_FAIL;
  constructor(public payload: unknown) {}
}

export class LoadUserSocialAccountsSuccess implements Action {
  readonly type = LOAD_USER_SOCIAL_ACCOUNTS_SUCCESS;
  constructor(public payload: IUserSocialAccounts) {}
}

export class UpdateUserFlag implements Action {
  readonly type = UPDATE_USER_FLAG;
  constructor(public payload: { name: string; value: boolean }) {}
}

export class UpdateUserFlagFail implements Action {
  readonly type = UPDATE_USER_FLAG_FAIL;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateUserFlagSuccess implements Action {
  readonly type = UPDATE_USER_FLAG_SUCCESS;
  constructor(public payload: IMongoResult) {}
}

export type UserAction =
  | ClearUserState
  | LoadUser
  | LoadUserData
  | LoadUserDataFail
  | LoadUserDataSuccess
  | UpdateUserData
  | UpdateUserDataFail
  | UpdateUserDataSuccess
  | UpdateProfileImg
  | UpdateProfileImgFail
  | UpdateProfileImgSuccess
  | LoadUserNotifications
  | LoadUserNotificationsFail
  | LoadUserNotificationsSuccess
  | SetUserAcceptedConditions
  | LoadUserSocialAccounts
  | LoadUserSocialAccountsFail
  | LoadUserSocialAccountsSuccess
  | UpdateUserFlag
  | UpdateUserFlagFail
  | UpdateUserFlagSuccess;
