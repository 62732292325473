import * as fromForms from '../actions/core-forms.actions';
import {ILanguage} from "../../models/language.interface";
import {ICountry} from "../../models/country.interface";
import {IGender} from "../../models/gender.interface";
import {IDiscount} from "../../models/discount.interface";

export interface FormsState {
  countryChoices: ICountry[];
  languageChoices: ILanguage[];
  genderChoices: IGender[];
  discountChoices: IDiscount[];
}

export const initialState: FormsState = {
  countryChoices: [],
  languageChoices: [],
  genderChoices: [],
  discountChoices: []

};

export function reducer(state = initialState, action: fromForms.FormsAction): FormsState {
  switch (action.type) {
    case fromForms.LOAD_FORMS_SUCCESS:
      return handleLoadFormsSuccess(state, action.payload);
    case fromForms.LOAD_FORMS_FAIL:
      return handleLoadFormsFail(state, action.payload);
  }
  return state;
}

// action handler
function handleLoadFormsSuccess(state, payload): FormsState {
  return {...payload};
}

function handleLoadFormsFail(state, payload): FormsState {
  return {...state};
}

export const getFormsCountryChoices = (state: FormsState) => state.countryChoices;
export const getFormsLanguageChoices = (state: FormsState) => state.languageChoices;
export const getFormsGenderChoices = (state: FormsState) => state.genderChoices;
export const getFormsDiscountChoices = (state: FormsState) => state.discountChoices;
