import { Action } from '@ngrx/store';

export const CLEAR_FORMS_STATE = '[core] Clear Forms State';

export const LOAD_FORMS = '[core] Load Forms';
export const LOAD_FORMS_FAIL = '[core] Load Forms Fail';
export const LOAD_FORMS_SUCCESS = '[core] Load Forms Success';


export class ClearFormsState implements Action {
  readonly type = CLEAR_FORMS_STATE;
}

export class LoadForms implements Action {
  readonly type = LOAD_FORMS;
}

export class LoadFormsFail implements Action {
  readonly type = LOAD_FORMS_FAIL;
  constructor(public payload: any) {}
}

export class LoadFormsSuccess implements Action {
  readonly type = LOAD_FORMS_SUCCESS;
  constructor(public payload: any) {}
}

export type FormsAction = ClearFormsState
  | LoadForms
  | LoadFormsFail
  | LoadFormsSuccess;
