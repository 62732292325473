import { Action } from '@ngrx/store';
import { ICoreMessage } from '../../models/message.interface';

export const CLEAR_MESSAGES = '[core] Clear Messages';

export const ADD_SUCCESS_MESSAGE = '[core] Add Success Message';
export const REMOVE_SUCCESS_MESSAGE = '[core] Remove Success Message';

export const ADD_WARNING_MESSAGE = '[core] Add Warning Message';
export const REMOVE_WARNING_MESSAGE = '[core] Remove Warning Message';

export const ADD_ERROR_MESSAGE = '[core] Add Error Message';
export const REMOVE_ERROR_MESSAGE = '[core] Remove Error Message';

export class ClearMessages implements Action {
  readonly type = CLEAR_MESSAGES;
}

export class AddSuccessMessage implements Action {
  readonly type = ADD_SUCCESS_MESSAGE;
  constructor(public payload: ICoreMessage) {}
}

export class RemoveSuccessMessage implements Action {
  readonly type = REMOVE_SUCCESS_MESSAGE;
  constructor(public payload: ICoreMessage) {}
}

export class AddWarningMessage implements Action {
  readonly type = ADD_WARNING_MESSAGE;
  constructor(public payload: ICoreMessage) {}
}

export class RemoveWarningMessage implements Action {
  readonly type = REMOVE_WARNING_MESSAGE;
  constructor(public payload: ICoreMessage) {}
}

export class AddErrorMessage implements Action {
  readonly type = ADD_ERROR_MESSAGE;
  constructor(public payload: ICoreMessage) {}
}

export class RemoveErrorMessage implements Action {
  readonly type = REMOVE_ERROR_MESSAGE;
  constructor(public payload: ICoreMessage) {}
}

export type MessageAction = ClearMessages
 | AddSuccessMessage
 | RemoveSuccessMessage
 | AddWarningMessage
 | RemoveWarningMessage
 | AddErrorMessage
 | RemoveErrorMessage;
