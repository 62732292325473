import { ICoreMessage } from '../../models/message.interface';
import { Action } from '@ngrx/store';

export const CLEAR_UISIDEBAR_STATE = '[core-sidebar] Clear UISidebar State';

export const LOAD_USER_CONTACTCOUNT = '[core-sidebar] Load User Contactcount';
export const LOAD_USER_CONTACTCOUNT_FAIL = '[core-sidebar] Load User Contactcount Fail';
export const LOAD_USER_CONTACTCOUNT_SUCCESS = '[core-sidebar] Load User Contactcount Success';

export class ClearUISidebarState implements Action {
  readonly type = CLEAR_UISIDEBAR_STATE;
}

export class LoadUserContactCount implements Action {
  readonly type = LOAD_USER_CONTACTCOUNT;
}

export class LoadUserContactCountFail implements Action {
  readonly type = LOAD_USER_CONTACTCOUNT_FAIL;
  constructor(public payload: ICoreMessage) {}
}

export class LoadUserContactCountSuccess implements Action {
  readonly type = LOAD_USER_CONTACTCOUNT_SUCCESS;
  constructor(public payload: any) {}
}


export type UISidebarAction = ClearUISidebarState
  | LoadUserContactCount
  | LoadUserContactCountFail
  | LoadUserContactCountSuccess;
