import * as fromUser from '../actions/core-user.actions';
import { IUser, initialUser } from '../../models/user.interface';

// statemodel for user
export interface UserState {
  data: IUser;
  acceptedConditions: boolean;
  loaded: boolean;
  loading: boolean;
}

// initial representation of user state
export const initialState: UserState = {
  data: initialUser,
  acceptedConditions: false,
  loaded: false,
  loading: false,
};

// user state reducer
export function reducer(state = initialState, action: fromUser.UserAction): UserState {
  switch (action.type) {
    case fromUser.CLEAR_USER_STATE:
      return handleClearUserState(state);
    case fromUser.LOAD_USER_DATA:
      return handleLoadUserData(state);
    case fromUser.LOAD_USER_DATA_FAIL:
      return handleLoadUserDataFail(state, action.payload);
    case fromUser.LOAD_USER_DATA_SUCCESS:
      return handleLoadUserDataSuccess(state, action.payload);
    case fromUser.LOAD_USER_NOTIFICATIONS:
      return handleLoadUserNotifications(state);
    case fromUser.UPDATE_USER_DATA_SUCCESS:
      return handleUpdateUserDataSuccess(state, action.payload);
    case fromUser.UPDATE_USER_DATA_FAIL:
      return handleUpdateUserDataFail(state);
    case fromUser.LOAD_USER_NOTIFICATIONS_FAIL:
      return handleLoadUserNotificationsFail(state, action.payload);
    case fromUser.LOAD_USER_NOTIFICATIONS_SUCCESS:
      return handleLoadUserNotificationsSuccess(state, action.payload);
    case fromUser.SET_USER_ACCEPTED_CONDITIONS:
      return handleSetUserAcceptedConditions(state, action.payload);
    case fromUser.LOAD_USER_SOCIAL_ACCOUNTS:
      return handleLoadUserSocialAccounts(state);
    case fromUser.LOAD_USER_SOCIAL_ACCOUNTS_FAIL:
      return handleLoadUserSocialAccountsFail(state);
    case fromUser.LOAD_USER_SOCIAL_ACCOUNTS_SUCCESS:
      return handleLoadUserSocialAccountsSuccess(state, action.payload);
    case fromUser.UPDATE_USER_FLAG:
      return handleUpdateUserFlag(state);
    case fromUser.UPDATE_USER_FLAG_FAIL:
      return handleUpdateUserFlagFail(state);
    case fromUser.UPDATE_USER_FLAG_SUCCESS:
      return handleUpdateUserFlagSuccess(state);
  }
  return state;
}

// action handler
function handleClearUserState(state): UserState {
  return { ...initialState };
}

function handleLoadUserData(state): UserState {
  return { ...state, loading: true };
}

function handleLoadUserDataFail(state, payload): UserState {
  return { ...state, loaded: false, loading: false };
}

function handleLoadUserDataSuccess(state, payload): UserState {
  const data = { ...state.data, ...payload };
  return { ...state, data, loading: false, loaded: true };
}

function handleUpdateUserDataSuccess(state, payload): UserState {
  const data = { ...state.data, ...payload };
  return { ...state, data, loading: false, loaded: true };
}

function handleUpdateUserDataFail(state): UserState {
  return { ...state, loading: false, loaded: false };
}

function handleLoadUserNotifications(state): UserState {
  return { ...state, loading: true };
}

function handleLoadUserNotificationsFail(state, payload): UserState {
  return { ...state, loaded: false, loading: false };
}

function handleLoadUserNotificationsSuccess(state, payload): UserState {
  const data = { ...state.data, notifications: payload };
  return { ...state, data, loading: false, loaded: true };
}

function handleSetUserAcceptedConditions(state, payload): UserState {
  return { ...state, acceptedConditions: payload };
}

function handleLoadUserSocialAccounts(state): UserState {
  return { ...state, loading: true, loaded: false };
}

function handleLoadUserSocialAccountsFail(state): UserState {
  return { ...state, loading: false, loaded: false };
}

function handleLoadUserSocialAccountsSuccess(state, payload): UserState {
  const data = { ...state.data, ...payload };
  return { ...state, data, loading: false, loaded: true };
}

function handleUpdateUserFlag(state): UserState {
  return { ...state, loading: true, loaded: false };
}

function handleUpdateUserFlagFail(state): UserState {
  return { ...state, loading: false, loaded: false };
}

function handleUpdateUserFlagSuccess(state): UserState {
  return { ...state, loading: false, loaded: true };
}

// selectors for slices of state
export const getUserLoading = (state: UserState) => state.loading;
export const getUserLoaded = (state: UserState) => state.loaded;
export const getUserAcceptedConditions = (state: UserState) => state.acceptedConditions;
export const getUser = (state: UserState) => state.data;
export const getUserNotifications = (state: UserState) => state.data.notifications;
export const getUserRoles = (state: UserState) => state.data.roles;
export const getUserLanguage = (state: UserState) => state.data.language;
export const getUserCountry = (state: UserState) => state.data.country;
export const getUserFlags = (state: UserState) => state.data.flags;
