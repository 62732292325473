import { Injectable } from '@angular/core';
import * as decode from 'jwt-decode';
import { ITokenDecoded } from '../../models/decoded-jwt.interface';

@Injectable()
export class JwtService {

  public getToken(): string {
    const token = localStorage.getItem('cockpit.credentials_token');
    if (token !== null) return token.replace(/['"]+/g, '');
    return null;
  }

  public getUserIdFromToken(): string {
    const decodedToken = this.decodeToken(localStorage.getItem('cockpit.credentials_token'));
    if (decodedToken !== undefined) return decodedToken.sub;
    return '';
  }

  public getUserRolesFromToken(): string[] {
    const decodedToken = this.decodeToken(localStorage.getItem('cockpit.credentials_token'));
    return decodedToken.rls.split(';');
  }

  public isExpired(): boolean {
    const token = this.decodeToken(localStorage.getItem('cockpit.credentials_token'));
    if (token === undefined) return false;
    const timestamNow = new Date().valueOf();
    const nowTokenFromat = Math.floor(timestamNow / 1000);
    return token.exp < nowTokenFromat;
  }

  public tokenValidforAuthGuard(): boolean {
    return !this.isExpired() && this.getToken() !== null ? true : false;
  }

  private decodeToken(token): ITokenDecoded {
    let decodedToken: ITokenDecoded;
    try {
      if (token !== null) {
        decodedToken = decode(token);
        return decodedToken;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
