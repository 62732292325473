import * as fromUISidebar from '../actions/core-ui-sidebar.actions';

export interface UISidebarState {
  contactsCount: {
    count: number;
    loading: boolean;
    loaded: boolean;
  };
}

export const initialState: UISidebarState = {
  contactsCount: {
    count: 0,
    loading: false,
    loaded: false
  }
};

export function reducer(state = initialState, action: fromUISidebar.UISidebarAction): UISidebarState {
  switch (action.type) {
    case fromUISidebar.CLEAR_UISIDEBAR_STATE:
      return handleClearUISidebarState(state);
    case fromUISidebar.LOAD_USER_CONTACTCOUNT:
      return handleLoadUserContactCount(state);
    case fromUISidebar.LOAD_USER_CONTACTCOUNT_FAIL:
      return handleLoadUserContactCountFail(state, action.payload);
    case fromUISidebar.LOAD_USER_CONTACTCOUNT_SUCCESS:
      return handleLoadUserContactCountSuccess(state, action.payload);
  }
  return state;
}

function handleClearUISidebarState(state): UISidebarState {
  return {...initialState};
}

function handleLoadUserContactCount(state): UISidebarState {
  const newContactsCount = {
    count: state.contactsCount.count,
    loading: true,
    loaded: false
  };
  return {...state, contactsCount: newContactsCount};
}

function handleLoadUserContactCountFail(state, payload): UISidebarState {
  const newContactsCount = {
    count: 0,
    loading: false,
    loaded: false
  };
  return {...state, contactsCount: newContactsCount};
}

function handleLoadUserContactCountSuccess(state, payload): UISidebarState {
  const newContactsCount = {
    count: payload,
    loading: false,
    loaded: true
  };
  return {...state, contactsCount: newContactsCount};
}

export const getUISidebarContactCount = (state: UISidebarState) => state.contactsCount.count;
export const getUISidebarContactCountLoading = (state: UISidebarState) => state.contactsCount.loading;
export const getUISidebarContactCountLoaded = (state: UISidebarState) => state.contactsCount.loaded;
