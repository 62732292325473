import { environmentEnums } from './environment.enums';

export const environment = {
  production: false,
  name: environmentEnums.STAGE,
  iFrame: {
    origin: 'https://stage-2018.forever-cockpit.com',
  },
  cockpitApi: 'https://stage-api.forever-cockpit.com',
  mediaHost: 'https://media.forever-cockpit.com/stage',
  protocol: 'http',
  pimUrl: 'https://stage.pim.be-forever.com',
  shopUrl: 'https://stage.be-forever.com',
  yoursUrl: 'https://forever-yours.eu',
  gtmID: 'GTM-NM46N97',
};
