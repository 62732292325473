import * as fromUser from './core-user.reducer';
import * as fromUi from './core-ui.reducer';
import * as fromUISidebar from './core-ui-sidebar.reducer';
import * as fromMessage from './core-message.reducer';
import * as fromForms from './core-forms.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export interface CoreState {
  user: fromUser.UserState;
  ui: fromUi.UIState;
  messages: fromMessage.MessageState;
  forms: fromForms.FormsState;
  sidebar: fromUISidebar.UISidebarState;
}

export const reducers: ActionReducerMap<CoreState> = {
  user: fromUser.reducer,
  ui: fromUi.reducer,
  sidebar: fromUISidebar.reducer,
  messages: fromMessage.reducer,
  forms: fromForms.reducer
};

// feature state
export const getCoreState = createFeatureSelector<CoreState>('core');
