<div id="user-panel-container" class="user-panel component" *ngIf="(isAuthenticated$ | async)">

    <!-- notifications -->
    <div ngbDropdown class="user-panel__notifications" (blur)="toggleDropdown('notifications-dropdown')">
        <button
            ngbDropdownToggle
            class="notifications__button"
            (click)="toggleDropdown('notifications-dropdown')">

            <div class="button__count" *ngIf="(user$ | async)?.notifications.length">{{ (user$ | async)?.notifications.length }}</div>
            <i class="i--sm--notifications button__icon"></i>

        </button>

        <div ngbDropdownMenu id="notifications-dropdown">
            <div class="dropdown__header">
                <!-- no new notifications -->
                <span *ngIf="(user$ | async)?.notifications.length === 0">
                    {{ 'NOTIFICATIONS_DROPDOWN.NOTHING_NEW' | translate }}
                </span>
                <!-- new notifications -->
                <span *ngIf="(user$ | async)?.notifications.length !== 0">
                    <strong>{{ (user$ | async)?.notifications.length }}</strong> {{ 'SIDEBAR.NOTIFICATIONS' | translate }}:
                </span>
            </div>
            <ul id="notifications-dropdown__list">
                <li
                    *ngFor="let notification of (user$ | async)?.notifications"
                    routerLink="/dashboard/notifications"
                    (click)="toggleDropdown('notifications-dropdown')"
                    >
                    <i class="icon-envelope"></i>{{ notification.name[selectedNotificationLanguage] }}
                </li>
            </ul>
        </div>
    </div>

    <!-- language selection -->
    <div ngbDropdown class="user-panel__language" (blur)="toggleDropdown('language-dropdown')">
        <ng-container *ngFor="let language of languages">
            <button
                ngbDropdownToggle
                *ngIf="isSelectedLanguage(language.id)"
                class="language__button"
                (click)="toggleDropdown('language-dropdown')">
                <span class="button__text">{{ transKey(language.transKey) | translate }}</span>
            </button>
        </ng-container>

        <div ngbDropdownMenu id="language-dropdown">
            <div class="dropdown__header">{{ 'LANGUAGE_LABEL' | translate }}</div>
            <ul>
                <ng-container *ngFor="let language of languages">
                    <li *ngIf="!isSelectedLanguage(language.id)" (click)="setLanguage(language.id); toggleDropdown('language-dropdown')">
                        <i class="icon-flag"></i>{{ transKey(language.transKey) | translate }}
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>

    <!-- quicklinks -->
    <div ngbDropdown class="user-panel__quicklinks" (blur)="toggleDropdown('quicklinks-dropdown')">
        <button
            ngbDropdownToggle
            class="quicklinks__button"
            (click)="toggleDropdown('quicklinks-dropdown')"
            >
            <span class="button__text">Quicklinks</span>
        </button>
        <div ngbDropdownMenu id="quicklinks-dropdown" class="dropdown">
            <div class="dropdown__header">Quicklinks</div>
            <ul>
                <li *ngFor="let quicklink of quicklinks" (click)="openExternalUrl(quicklink.url); toggleDropdown('quicklinks-dropdown')">
                    <i class="icon-arrow-right-circle"></i>{{ quicklink.label }}
                </li>
            </ul>
        </div>
    </div>

    <!-- user dropdown -->
    <div ngbDropdown class="user-panel__profile" (blur)="toggleDropdown('user-dropdown')">
        <button
            class="profile__button"
            ngbDropdownToggle
            (click)="toggleDropdown('user-dropdown')"
            >
            <img
                [ngClass]="{ 'fallback-img-male': gender === 'm', 'fallback-img-female': gender === 'f' }"
                [src]="profileImgUrl$ | async"

                class="button__image"
                />
        </button>
            <div ngbDropdownMenu id="user-dropdown">
                <div class="dropdown__header">
                    {{ (user$ | async).firstName + ' ' + (user$ | async).lastName }}
                </div>
                <ul>
                    <li routerLink="user/profile" (click)="toggleDropdown('user-dropdown')">
                        <i class="icon-user"></i>{{ 'USER_DROPDOWN.USER_PROFILE' | translate }}
                    </li>
                    <li routerLink="user/login" (click)="toggleDropdown('user-dropdown')">
                        <i class="icon-logout"></i>{{ 'USER_DROPDOWN.LOG_OUT' | translate }}
                    </li>
                </ul>
            </div>
    </div>

    <!-- mobile control panel -->
    <div class="user-panel__mobile">
        <div (click)="toggleDropdown('notifications-dropdown')"><i class="icon-bell"></i></div>
        <div (click)="toggleDropdown('language-dropdown')"><i class="icon-globe"></i></div>
        <div (click)="toggleDropdown('quicklinks-dropdown')"><i class="icon-link"></i></div>
        <div (click)="toggleDropdown('user-dropdown')"><i class="icon-user"></i></div>
    </div>
</div>
