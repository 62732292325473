import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer, effects } from './store';
import { CustomRouterSerializer } from './store/utils/router-serializer';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as fromGuards from './guards';
import { TrackJsErrorHandler } from './trackjs.handler';
import { ErrorHandler } from '@angular/core';

// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { HttpLoaderFactory } from './http-loader.factory';
import { ConditionsService } from './modules/feature-modules/conditions/services';
import { environmentEnums } from '../environments/environment.enums';
import { Angulartics2Module } from 'angulartics2';

export const metaReducers: MetaReducer<any>[] = !environment.production ? [storeFreeze] : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgbModule,
    AppRoutingModule,
    StoreModule.forRoot(reducer, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 50, logOnly: true }),
    FormsModule,
    ReactiveFormsModule,
    Angulartics2Module.forRoot(),
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterSerializer },
    !environment.production && environment.name !== environmentEnums.STAGE
      ? []
      : { provide: ErrorHandler, useClass: TrackJsErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    ...fromGuards.guards,
    ConditionsService
  ],
  exports: [StoreModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
