import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IToast } from '../../models/toast.interface';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  @Input() toast: IToast;
  @Output() closed = new EventEmitter<IToast>();

  constructor() {}

  ngOnInit() {
    this.addToastClass();
    if (this.toast.displayTime > 0) {
      this.closeAfterTimeout();
    }
  }

  public addToastClass(): void {
    const toastClassIncluded = this.toast.classList.includes('flp-toast');
    if (!toastClassIncluded) {
      this.toast.classList.unshift('flp-toast');
    }
  }

  public close(): void {
    this.closed.emit(this.toast);
  }

  public closeAfterTimeout(): void {
    setTimeout(this.close.bind(this), this.toast.displayTime);
  }
}
