import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPanelComponent } from './components/info-panel/info-panel.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import { HttpLoaderFactory } from '../../../http-loader.factory';
import { SafeModule } from '../safe-module/safe.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }}),
    SafeModule
  ],
  declarations: [InfoPanelComponent],
  exports: [InfoPanelComponent]
})
export class InfoPanelModule { }
