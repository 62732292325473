<div class="wrapper">
  <div class="bd-highlight wrapper__info-icon">
    <img class="info-icon__icon" src="/assets/img/icons/help.svg">
  </div>

  <div class="wrapper__info flex-grow-1 bd-highlight" [@collapse]="stateName" [innerHTML]="infoText | translate | safe: 'html'"></div>

    <div class="button-wrapper">
      <i class="d-none d-xl-block" [ngClass]="{'i--xl--maximize--button': !isExpanded, 'i--xl--minimize--button': isExpanded}" (click)="toggleState()"></i>
      <i class="d-xl-none" [ngClass]="{'i--xl--maximize--button': !isExpanded, 'i--xl--minimize--button': isExpanded}" (click)="toggleState()"></i>
    </div>
</div>
