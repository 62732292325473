import { Component, Input, OnInit } from '@angular/core';
import { collapse } from '../../../../../../assets/animations/info-panel.animations';

@Component({
  selector: 'app-warning-panel',
  templateUrl: './warning-panel.component.html',
  styleUrls: ['./warning-panel.component.scss'],
  animations: [collapse],
})
export class WarningPanelComponent implements OnInit {
  @Input() warningText: string;
  isExpanded = false;

  constructor() {}

  ngOnInit() {}

  get stateName() {
    return this.isExpanded ? 'open' : 'close';
  }

  toggleState() {
    this.isExpanded = !this.isExpanded;
  }
}
