import { trigger, state, style, transition, animate } from '@angular/animations';

export const collapse = trigger('collapse', [
  state('close', style({
    height: '30px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  })),
  state('open', style( {
    height: '*',
    whiteSpace: '*',
    textOverflow: '*',
  })),
  transition('close => open', animate('500ms ease-in')),
  transition('open => close', animate('500ms ease-out')),
]);

