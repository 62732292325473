<div class="warning-panel">
  <i class="i--md--warning warning-panel__icon"></i>
  <span class="warning-panel__content">
    <ng-content></ng-content>
  </span>
</div>

<div class="wrapper">
  <div class="bd-highlight wrapper__warning-icon">
    <img class="warning-icon__icon" src="/assets/img/icons/warning.svg">
  </div>

  <div class="wrapper__warning flex-grow-1 bd-highlight" [@collapse]="stateName" [innerHTML]="warningText | translate | safe: 'html'"></div>

    <div class="button-wrapper">
      <i class="d-none d-xl-block" [ngClass]="{'i--xl--maximize--button': !isExpanded, 'i--xl--minimize--button': isExpanded}" (click)="toggleState()"></i>
      <i class="d-xl-none" [ngClass]="{'i--xl--maximize--button': !isExpanded, 'i--xl--minimize--button': isExpanded}" (click)="toggleState()"></i>
    </div>
</div>
