import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as userActions from '../actions/core-user.actions';
import * as uiActions from '../actions/core-ui.actions';
import * as sidebarActions from '../actions/core-ui-sidebar.actions';
import * as messageActions from '../actions/core-message.actions';
import * as fromServices from '../../services';
import { IMongoResult } from '../../models/mongo-result.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private userService: fromServices.UserService) {}

  @Effect()
  loadUser$ = this.actions$.pipe(
    ofType(userActions.LOAD_USER),
    switchMap(() => [
      new userActions.LoadUserData(),
      new userActions.LoadUserNotifications(),
      new sidebarActions.LoadUserContactCount(),
    ]),
  );

  @Effect()
  loadUserData$ = this.actions$.pipe(
    ofType(userActions.LOAD_USER_DATA),
    switchMap(() =>
      this.userService.getUser().pipe(
        map((user) => new userActions.LoadUserDataSuccess(user)),
        catchError((error) => of(new userActions.LoadUserDataFail(error))),
      ),
    ),
  );

  @Effect()
  broadcastUserData$ = this.actions$.pipe(
    ofType(userActions.LOAD_USER_DATA_SUCCESS),
    map((action: userActions.LoadUserDataSuccess) => new uiActions.StoreUiLanguageSelection(action.payload.language)),
  );

  @Effect()
  loadUserNotifications$ = this.actions$.pipe(
    ofType(userActions.LOAD_USER_NOTIFICATIONS),
    switchMap(() =>
      this.userService.getUnreadUserNotifications().pipe(
        map((notifications) => new userActions.LoadUserNotificationsSuccess(notifications)),
        catchError((error) => of(new userActions.LoadUserNotificationsFail(error))),
      ),
    ),
  );

  @Effect()
  updateUserData$ = this.actions$.pipe(
    ofType(userActions.UPDATE_USER_DATA),
    switchMap((action: userActions.UpdateUserData) =>
      this.userService.updateUser(action.payload).pipe(
        switchMap((resUser) => [
          new messageActions.AddSuccessMessage({ message: 'USER_PROFILE.PROFILE_SAVE_SUCCESS' }),
          new userActions.UpdateUserDataSuccess(resUser),
        ]),
        catchError((error) => [
          new messageActions.AddErrorMessage({ message: 'USER_PROFILE.PROFILE_SAVE_ERROR' }),
          new userActions.UpdateUserDataFail(error),
        ]),
      ),
    ),
  );

  @Effect()
  updateProfileImg$ = this.actions$.pipe(
    ofType(userActions.UPDATE_PROFILE_IMG),
    switchMap((action: userActions.UpdateProfileImg) =>
      this.userService.updateProfileImg(action.payload).pipe(
        switchMap((resUser) => [
          new messageActions.AddSuccessMessage({ message: 'USER_PROFILE.PROFILE_SAVE_PICTURE_SUCCESS' }),
          new userActions.UpdateProfileImgSuccess(resUser),
          new userActions.LoadUserDataSuccess(resUser),
        ]),
        catchError((error) => [
          new messageActions.AddErrorMessage({ message: 'USER_PROFILE.PROFILE_SAVE_ERROR' }),
          new userActions.UpdateProfileImgFail(error),
        ]),
      ),
    ),
  );

  @Effect()
  loadUserSocialAccounts$ = this.actions$.pipe(
    ofType(userActions.LOAD_USER_SOCIAL_ACCOUNTS),
    switchMap((action: userActions.LoadUserSocialAccounts) =>
      this.userService.loadUserSocialAccounts().pipe(
        switchMap((resAccounts) => [
          new messageActions.AddSuccessMessage({ message: 'USER_PROFILE.PROFILE_SYNC_SOCIAL_ACCOUNTS_SUCCESS' }),
          new userActions.LoadUserSocialAccountsSuccess(resAccounts),
        ]),
        catchError((error) => [
          new messageActions.AddErrorMessage({ message: 'USER_PROFILE.PROFILE_SYNC_SOCIAL_ACCOUNTS_FAIL' }),
          new userActions.LoadUserSocialAccountsFail(error),
        ]),
      ),
    ),
  );

  @Effect()
  updateUserFlag$ = this.actions$.pipe(
    ofType(userActions.UPDATE_USER_FLAG),
    switchMap((action: userActions.UpdateUserFlag) => {
      const { name, value } = action.payload;
      return this.userService.updateUserFlag(name, value).pipe(
        switchMap((result: IMongoResult) => [
          new userActions.UpdateUserFlagSuccess(result),
          new userActions.LoadUserData(),
        ]),
        catchError((error: HttpErrorResponse) => [
          new messageActions.AddErrorMessage({ message: 'ERROR_GENERIC' }),
          new userActions.UpdateUserFlagFail(error),
        ]),
      );
    }),
  );
}
