<!-- Block -->
<div class='popup' *ngIf='visible' [@fadeInOutFast]="'in'">
  <!-- Element -->
  <div class='popup__backdrop' (click)='closed()'></div>

  <!-- Element / Block -->
  <div class='popup__content'>

    <!-- Element / Block -->
    <div class="popup__header">

      <!-- Element / Block -->
      <div class="popup__header__title-wrapper">
        <div class="popup__header__title-wrapper__title"> <span>{{ title | translate }}</span> </div>
        <div class="popup__header__title-wrapper__close-icon"> <i id='popup-close' class='i--md--close--button popup__close' (click)='closed()'></i> </div>
      </div>

      <div class="popup__header__description"> <span>{{ description | translate }}</span> </div>

    </div>

    <div class='popup__body'>
      <ng-content></ng-content>
    </div>

  </div>
</div>
