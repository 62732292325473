import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtService } from '../../modules/core/services';
import { CoreStateFacade } from '../../modules/core/core-state.facade';

import { Store } from '@ngrx/store';
import * as fromApp from '../../store';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private store: Store<fromApp.AppState>,
    private jwtService: JwtService,
    private coreFacade: CoreStateFacade,
    private router: Router,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (!this.jwtService.isExpired() && this.jwtService.getToken() !== null) {
        this.coreFacade.setUiStateAuthenticated();
        return true;
      } else {
        this.coreFacade.setUiStateDeauthenticated();
        this.store.dispatch(new fromApp.Go({ path: ['/user/login'], query: { returnTo: state.url } }));
        return false;
      }
  }

}
