import {Component, Input, OnInit} from '@angular/core';
import {collapse} from '../../../../../../assets/animations/info-panel.animations';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
    animations: [collapse]
})
export class InfoPanelComponent implements OnInit {
  @Input() infoText: string;
  isExpanded = false;

  constructor() { }

  ngOnInit() {
  }

  get stateName() {
    return this.isExpanded ? 'open' : 'close';
  }

  toggleState() {
    this.isExpanded = !this.isExpanded;
  }

}
