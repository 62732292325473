import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarningPanelComponent } from './components/warning-panel/warning-panel.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '../../../http-loader.factory';
import { SafeModule } from '../safe-module/safe.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SafeModule,
  ],
  declarations: [WarningPanelComponent],
  exports: [WarningPanelComponent],
})
export class WarningPanelModule {}
